import React, { useEffect, useState } from 'react'

export default function HGROUP(props) {
    const [mStyle, setStyle] = useState({
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        display:'inline',
        gap: 5,
        backgroundColor: 'transparent',
        justifyContent: 'center',
        padding: '2px',
        paddingBottom:'0',
        paddingTop:'0',
        paddingLeft:'0',
        paddingRight:'0',
        marginLeft:'0',
        marginRight:'0',
        marginTop:'0',
        marginBottom:'0',
        top:'0',
        bottom:'0',
        left:'0',
        right:'0',
        margin:'0',
        overflow : 'visible',
        alignItems : 'unset',
        justifyContent:'unset',
        alignContent:'center',
        boxShadow:'none',
        borderRadius : '0px',
        backgroundImage: `url(" ")`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        flexWrap:'no-wrap'
    }
    );

    const [mChildren, setChildren] = useState(null);


    useEffect(() => {
        if (props) {
            /**
             * Initialize Component Styles
             */
            if (props.hasOwnProperty('width')) {
                setStyle((lStyle) => ({ ...lStyle, width: props.width }));
            }

            if (props.hasOwnProperty('height')) {
                setStyle((lStyle) => ({ ...lStyle, height: props.height }));
            }

            if (props.hasOwnProperty('gap')) {
                setStyle((lStyle) => ({ ...lStyle, gap: props.gap }));
            }
            if (props.hasOwnProperty('backgroundImage')) {
                setStyle((lStyle) => ({ ...lStyle, backgroundImage: props.backgroundImage }));
            }
            if (props.hasOwnProperty('backgroundRepeat')) {
                setStyle((lStyle) => ({ ...lStyle, backgroundRepeat: props.backgroundRepeat }));
            }
            if (props.hasOwnProperty('backgroundSize')) {
                setStyle((lStyle) => ({ ...lStyle, backgroundSize: props.backgroundSize }));
            }
            if (props.hasOwnProperty('flexWrap')) {
                setStyle((lStyle) => ({ ...lStyle, flexWrap: props.flexWrap }));
            }

            if (props.hasOwnProperty('backgroundColor')) {
                setStyle((lStyle) => ({ ...lStyle, backgroundColor: props.backgroundColor }));
            }
            if (props.hasOwnProperty('justifyContent')) {
                setStyle((lStyle) => ({ ...lStyle, justifyContent: props.justifyContent }));
            }
            if (props.hasOwnProperty('padding')) {
                setStyle((lStyle) => ({ ...lStyle, padding: props.padding }));
            }
            if (props.hasOwnProperty('paddingBottom')) {
                setStyle((lStyle) => ({ ...lStyle, paddingBottom: props.paddingBottom }));
            }
            if (props.hasOwnProperty('paddingTop')) {
                setStyle((lStyle) => ({ ...lStyle, paddingTop: props.paddingTop }));
            }
            if (props.hasOwnProperty('paddingLeft')) {
                setStyle((lStyle) => ({ ...lStyle, paddingLeft: props.paddingLeft }));
            }
            if (props.hasOwnProperty('paddingRight')) {
                setStyle((lStyle) => ({ ...lStyle, paddingRight: props.paddingRight }));
            }
            if (props.hasOwnProperty('marginLeft')) {
                setStyle((lStyle) => ({ ...lStyle, marginLeft: props.marginLeft }));
            }
            if (props.hasOwnProperty('marginRight')) {
                setStyle((lStyle) => ({ ...lStyle, marginRight: props.marginRight }));
            }
            if (props.hasOwnProperty('marginTop')) {
                setStyle((lStyle) => ({ ...lStyle, marginTop: props.marginTop }));
            }
            if (props.hasOwnProperty('marginBottom')) {
                setStyle((lStyle) => ({ ...lStyle, marginBottom: props.marginBottom }));
            }
            if (props.hasOwnProperty('top')) {
                setStyle((lStyle) => ({ ...lStyle, top: props.top }));
            }
            if (props.hasOwnProperty('bottom')) {
                setStyle((lStyle) => ({ ...lStyle, bottom: props.bottom }));
            }
            if (props.hasOwnProperty('left')) {
                setStyle((lStyle) => ({ ...lStyle, left: props.left }));
            }
            if (props.hasOwnProperty('right')) {
                setStyle((lStyle) => ({ ...lStyle, right: props.right }));
            }
            if (props.hasOwnProperty('margin')) {
                setStyle((lStyle) => ({ ...lStyle, margin: props.margin }));
            }
            if (props.hasOwnProperty('scroll')) {
                setStyle((lStyle) => ({ ...lStyle, overflow : props.scroll }));
            }
            if (props.hasOwnProperty('alignItems')) {
                setStyle((lStyle) => ({ ...lStyle, alignItems: props.alignItems }));
            }
            if (props.hasOwnProperty('justifyContent')) {
                setStyle((lStyle) => ({ ...lStyle, justifyContent: props.justifyContent }));
            }
            if (props.hasOwnProperty('alignContent')) {
                setStyle((lStyle) => ({ ...lStyle, alignContent: props.alignContent }));
            }
            if (props.hasOwnProperty('boxShadow')) {
                setStyle((lStyle) => ({ ...lStyle, boxShadow: props.boxShadow }));
            }
            if (props.hasOwnProperty('flexDirection')) {
                setStyle((lStyle) => ({ ...lStyle, flexDirection: props.flexDirection }));
            }
            if (props.hasOwnProperty('borderRadius')) {
                setStyle((lStyle) => ({ ...lStyle, borderRadius: props.borderRadius }));
            }
            if (props.hasOwnProperty('display')) {
                setStyle((lStyle) => ({ ...lStyle, display: props.display }));
            }
             /**
             * Add Children
             */
            if (props.hasOwnProperty('children')) {
                setChildren(props.children);
            }
        }
    }, [props]);

    return (
        <div style={mStyle}>
            {mChildren}
        </div>
    )
}
