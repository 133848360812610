function fnGetCurrentDate(lType = 'save', lSeparator = '-')
{
    var lCurrentDate    = null;
    var lNewDate        = new Date();
    var lDay            = lNewDate.getDate();
    var lMonth          = lNewDate.getMonth() + 1;
    var lYear           = lNewDate.getFullYear();
    
    if(lType === 'save')
    {
        lCurrentDate = `${lYear}${lSeparator}${lMonth<10?`0${lMonth}`:`${lMonth}`}${lSeparator}${lDay<10?`0${lDay}`:`${lDay}`}`;
    }
    else if(lType === 'display')
    {
        lCurrentDate = `${lDay<10?`0${lDay}`:`${lDay}`}${lSeparator}${lMonth<10?`0${lMonth}`:`${lMonth}`}${lSeparator}${lYear}`;
    }

    return lCurrentDate;
}

function fnGeDateStringInIndiaFormat(lDateString)
{
    let lRetValue = null;
    let lDate = null;
    
    if(lDateString && (lDateString instanceof Date))
    {
        lDate = fnGetDateToString(lDateString);
    }
    else
    {
        lDate = fnGetStringToDate(lDateString);
    }

    if(lDate)
    {
        lRetValue = fnGetDateString(lDate);
    }

    return lRetValue;
}

function fnGetDateString(lDate, lType = 'display', lSeparator = '-')
{
    var lDateComponents = lDate.split(lSeparator);
    var lDay            = null;
    var lMonth          = null;
    var lYear           = null;
    var lRetVal = null;
    
    if(lType === 'save')
    {
        lDay            = lDateComponents[2];
        lMonth          = lDateComponents[1];
        lYear           = lDateComponents[0];
    }
    else if(lType === 'display')
    {
        lDay            = lDateComponents[0];
        lMonth          = lDateComponents[1];
        lYear           = lDateComponents[2];
    }

    if(lDay && lMonth && lYear)
    {
        var lDateObj = new Date(`${lYear}${lSeparator}${lMonth<10?`0${lMonth}`:`${lMonth}`}${lSeparator}${lDay<10?`0${lDay}`:`${lDay}`}`);
        lRetVal = lDateObj.toDateString();
    }

    return lRetVal;
}

function fnGetCurrentTime()
{
    var lCurrentTime;
    var lNewDate    = new Date();
    let lHours      = lNewDate.getHours();
    let lMinutes    = lNewDate.getMinutes();
    let lSeconds    = lNewDate.getSeconds();

    lCurrentTime = (`${lHours}:${lMinutes}:${lSeconds}`);

    return lCurrentTime;
}

function fnGetTimeFromDate(lDate)
{
    var lTime;
    if(lDate && (lDate instanceof Date))
    {
        let lHours      = lDate.getHours();
        let lMinutes    = lDate.getMinutes();
        let lSeconds    = lDate.getSeconds();

        lTime = (`${lHours}:${lMinutes}:${lSeconds}`);
    }

    return lTime;
}

function fnGetCurrentTimestamp(lSeparator = '-')
{
    var lCurrentTS  = null;
    var lNewDate    = new Date();
    var lDay        = lNewDate.getDate();
    var lMonth      = lNewDate.getMonth() + 1;
    var lYear       = lNewDate.getFullYear();

    let lHours      = lNewDate.getHours();
    let lMinutes    = lNewDate.getMinutes();
    let lSeconds    = lNewDate.getSeconds();

    var lCurrentDate = `${lYear}${lSeparator}${lMonth<10?`0${lMonth}`:`${lMonth}`}${lSeparator}${lDay<10?`0${lDay}`:`${lDay}`}`;
    var lCurrentTime = (`${lHours}:${lMinutes}:${lSeconds}`);
    lCurrentTS = lCurrentDate + " " + lCurrentTime;

    return lCurrentTS;
}

function fnGetLongTimestamp()
{
    var lCurrentTS     = null;
    var lNewDate       = new Date();
    var lDay           = lNewDate.getDate();
    var lMonth         = lNewDate.getMonth() + 1;
    var lYear          = lNewDate.getFullYear();
   
    let lHours         = lNewDate.getHours();
    let lMinutes       = lNewDate.getMinutes();
    let lSeconds       = lNewDate.getSeconds();
    let lMilliSeconds  = lNewDate.getMilliseconds();

    var lCurrentDate = `${lYear}${lMonth<10?`0${lMonth}`:`${lMonth}`}${lDay<10?`0${lDay}`:`${lDay}`}`;
    var lCurrentTime = (`${lHours}${lMinutes}${lSeconds}${lMilliSeconds}`);
    lCurrentTS = lCurrentDate + lCurrentTime;

    return lCurrentTS;
}


function fnGetDateToString(lDate, lType = 'save', lSeparator = '-')
{
    let lRetVal;
    if(lDate)
    {
        let lDay = lDate.getDate();
        let lMonth = lDate.getMonth() + 1;
        let lYear = lDate.getFullYear();
    
        if(lType === 'save')
        {
            lRetVal = `${lYear}${lSeparator}${lMonth<10?`0${lMonth}`:`${lMonth}`}${lSeparator}${lDay<10?`0${lDay}`:`${lDay}`}`;
        }
        else
        {
            lRetVal = `${lDay<10?`0${lDay}`:`${lDay}`}${lSeparator}${lMonth<10?`0${lMonth}`:`${lMonth}`}${lSeparator}${lYear}`;
        }
    }

    return lRetVal;
}

function fnGetTimeString(lDate)
{
    var lRetVal;
    if(lDate && (lDate instanceof Date))
    {
        var lHourString = lDate.getHours();
        if(!lHourString)
        {
            lHourString = '00';
        }
        else if(lHourString.length < 2)
        {
            lHourString = '0' + lHourString;
        }

        var lMinutesString = lDate.getMinutes();
        if(!lMinutesString)
        {
            lMinutesString = '00';
        }
        else if(lMinutesString.length < 2)
        {
            lMinutesString = '0' + lMinutesString;
        }

        lRetVal = lHourString + ':' + lMinutesString;
    }

    return lRetVal;
}

function fnGetStringToDate(lDate)
{
    var lRetVal;
    if(lDate)
    {
        lRetVal = new Date(lDate);
    }

    return lRetVal;
}

function fnDateAdd(lDate, interval, lUnits)
{
    var lRetValue = undefined;
    if((lDate instanceof Date))
    {
        lRetValue = new Date(lDate);
        var checkRollover = function() { if(lRetValue.getDate() !== lDate.getDate()) lRetValue.setDate(0);};
        switch(String(interval).toLowerCase()) 
        {
            case 'year'   :  
                lRetValue.setFullYear(lRetValue.getFullYear() + lUnits); checkRollover();  
                break;

            case 'quarter':  
                lRetValue.setMonth(lRetValue.getMonth() + 3*lUnits); checkRollover();
                break;

            case 'month'  :  
                lRetValue.setMonth(lRetValue.getMonth() + lUnits); checkRollover();  
                break;

            case 'week'   :  
                lRetValue.setDate(lRetValue.getDate() + 7*lUnits);  
                break;

            case 'day'    :  
                lRetValue.setDate(lRetValue.getDate() + lUnits);  
                break;

            case 'hour'   :  
                lRetValue.setTime(lRetValue.getTime() + lUnits*3600000);  
                break;

            case 'minute' :  
                lRetValue.setTime(lRetValue.getTime() + lUnits*60000);  
                break;

            case 'second' :  
                lRetValue.setTime(lRetValue.getTime() + lUnits*1000);  
                break;

            default       :  
                lRetValue = undefined;  
                break;
        }
    }

    return lRetValue;
}

function fnGetMonthFromIndex(lMonthIndex)
{
    let lMonth = null;
    let lMonthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    if(lMonthIndex > 0 && lMonthIndex <= 12)
    {
        lMonth = lMonthNames[lMonthIndex - 1];
    }

    return lMonth;
}

function fnGetDaysInMonth(lMonth, lYear) 
{
    let lDays = [];
    if(lMonth && lYear)
    {
        let lMonthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        let lMonthNo = lMonthNames.indexOf(lMonth);
        let lDate = new Date(lYear, lMonthNo, 1);

        let lCurDate = new Date();
        let lCurDay   = lCurDate.getDate();
        let lCurMonth = lCurDate.getMonth() + 1;
        let lCurYear  = lCurDate.getFullYear();

        while (lDate.getMonth() === lMonthNo) 
        {
            let lValid = true;
            let lmDate = lDate.getDate();
            if((lYear === lCurYear) && (lMonthNames.indexOf(lMonth) + 1 === lCurMonth) && (lmDate > lCurDay))
            {
                lValid = false;
            }

            if(lValid)
            {
                lDays.push(fnGetDateToString(new Date(lDate), 'display'));
            }

            lDate.setDate(lDate.getDate() + 1);
        }
    }

    return lDays;
}

function fnGetDaysRange(lSDate, lEDate) 
{
    let lDays = [];
    if(lSDate && lEDate)
    {
        let lStartDate = fnGetStringToDate(lSDate);
        let lEndDate = fnGetStringToDate(lEDate);
        if( Date.parse(lEndDate) > Date.parse(lStartDate) )
        {
            let lDate = lStartDate; 
            while(lEndDate.getTime() === lDate.getTime())
            {
                lDays.push(fnGetDateToString(lDate));
                lDate.setDate(lDate.getDate() + 1);
            }
        }
    }

    return lDays;
}

export 
{ 
    fnGetCurrentDate, 
    fnGetCurrentTime, 
    fnGetCurrentTimestamp , 
    fnGetDateToString, 
    fnGetTimeString, 
    fnGetStringToDate, 
    fnGetDateString, 
    fnGetTimeFromDate, 
    fnDateAdd, 
    fnGeDateStringInIndiaFormat,
    fnGetMonthFromIndex,
    fnGetDaysInMonth,
    fnGetDaysRange,
    fnGetLongTimestamp
};