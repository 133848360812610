
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CS_Topbar from './Screens/CS_Topbar';
import CS_HomePage from './Screens/CS_HomePage';
import CS_About from './Screens/CS_About.js';
import CS_Gallery from './Screens/CS_Gallery.js';
import CS_Blog from './Screens/CS_Blog';
import CS_Admin from './Screens/CS_Admin';
import CS_Contact from './Screens/CS_Contact.js';
import CS_blogPost from './Screens/CS_blogPost';
import { DataContextProvider } from './context/DataContext';
// import './assets/theme.css'
import './assets/website.css'
import 'primeicons/primeicons.css';

function App() {
  return (
    <DataContextProvider>
       <Routes>
        <Route path="/" element={<CS_Topbar />}>
          <Route index element={<CS_HomePage />} />
          <Route path="home" element={<CS_HomePage />} />
          {/* <Route path="about" element={<SampleTest />} /> */}
          <Route path="gallery" element={<CS_Gallery />} />
          <Route path="blogs" element={<CS_Blog />} />
          <Route path="admin" element={<CS_Admin />} />
          <Route path="contact" element={<CS_Contact />} />
          <Route path="blogpost" element={<CS_blogPost />} />
        </Route>
      </Routes>
    </DataContextProvider>
  );
}

export default App;