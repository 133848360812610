import React, { useState } from 'react'

export default function CSFileUpload(lProps) {
 
    return (
        <div className='p-2 shadow-2 border-round'>
            <input
                className='fileInput '
                id={lProps.pId}
                type="file"
                accept="image/jpeg, image/png"
                onChange={(e) => lProps.pOnChange(e.target.id, e.target.files)}
            />
             <img src={lProps.pImage} width={100} height= {100} style={{objectFit:'contain'}}/>
        </div>
    )
}
