export  const CSImages =
[
    {
        "id": 1,
        "title": "text",
        "url": 'web/photos/image_ (1).png',
    },
    {
        "id": 2,
        "title": "text",
        "url": 'web/photos/image_ (2).png',
    },
    {
        "id": 3,
        "title": "text",
        "url": 'web/photos/image_ (3).png',
    },
    {
        "id": 4,
        "title": "text",
        "url": 'web/photos/image_ (4).png',
    },
    {
        "id": 5,
        "title": "text",
        "url": 'web/photos/image_ (5).png',
    },
    {
        "id": 6,
        "title": "text",
        "url": 'web/photos/image_ (6).png',
    },
    {
        "id": 7,
        "title": "text",
        "url": 'web/photos/image_ (7).png',
    },
    {
        "id": 8,
        "title": "text",
        "url": 'web/photos/image_ (8).png',
    },
    {
        "id": 9,
        "title": "text",
        "url": 'web/photos/image_ (9).png',
    },
    {
        "id": 10,
        "title": "text",
        "url": 'web/photos/image_ (10).png',
    },
    {
        "id": 11,
        "title": "text",
        "url": 'web/photos/image_ (11).png',
    },
    {
        "id": 12,
        "title": "text",
        "url": 'web/photos/image_ (12).png',
    },
    {
        "id": 13,
        "title": "text",
        "url": 'web/photos/image_ (13).png',
    },
    {
        "id": 14,
        "title": "text",
        "url": 'web/photos/image_ (14).png',
    },
    {
        "id": 15,
        "title": "text",
        "url": 'web/photos/image_ (15).png',
    },
    {
        "id": 16,
        "title": "text",
        "url": 'web/photos/image_ (16).png',
    },
    {
        "id": 17,
        "title": "text",
        "url": 'web/photos/image_ (17).png',
    },
    {
        "id": 18,
        "title": "text",
        "url": 'web/photos/image_ (18).png',
    },
    {
        "id": 19,
        "title": "text",
        "url": 'web/photos/image_ (19).png',
    },
    {
        "id": 20,
        "title": "text",
        "url": 'web/photos/image_ (20).png',
    },
    {
        "id": 21,
        "title": "text",
        "url": 'web/photos/image_ (21).png',
    },
    {
        "id": 22,
        "title": "text",
        "url": 'web/photos/image_ (22).png',
    },
    {
        "id": 23,
        "title": "text",
        "url": 'web/photos/image_ (23).png',
    },
    {
        "id": 24,
        "title": "text",
        "url": 'web/photos/image_ (24).png',
    },
    {
        "id": 25,
        "title": "text",
        "url": 'web/photos/image_ (25).png',
    },
    {
        "id": 26,
        "title": "text",
        "url": 'web/photos/image_ (26).png',
    },
    
    
];