import React from 'react'
import { useState } from 'react';
function Slider() {
    const artdata = [
        {
            category: 'Technology',
            image: '1.jpg',
            text: 'Occaecat deserunt sunt eu eu amet ut dolor. Eiusmod anim nulla eu voluptate iruis eu id voluptate et laborum. Exercitation ut fugiat anim ex',
            author: 'Name1',
        },
        {
            category: 'Art',
            image: '2.jpg',
            text: ' nostrud occaecat duis. Cillum eu id voluptate et laborum. Exercitation ut fugiat anim ex uExercitation ut fugiat anim ex ut.',
            author: 'Name2',
        },
        {
            category: 'Dynamo',
            image: '3.jpg',
            text: ' Eiusmod anim nulla eu voluptate irure ea nostrud occaecat duis. Cillum eu id voluptate et laborum. Exercitation ut fugiat anim ex ut.',
            author: 'Name3',
        }

    ];

    const [article, setArticle] = useState(artdata);

    const datacard = article.map((art_obj) =>
        <div className="col-12 lg:col-4 p-3">
            <div className="shadow-2 border-round h-full bg-primary">
                <img src='https://wallpaperaccess.com/full/746130.jpg' className="block w-full border-round-top" alt="Image" />
                <div className="p-4">
                    <span className="block font-medium text-white-600 mb-2">{art_obj.author}</span>
                    <div className="text-xl text-white-600 font-medium mb-2 line-height-3 ">{art_obj.category}</div>
                    <div className="text-xs font-bold text-surface  mb-1">{art_obj.text}</div>

                   
                </div>
            </div>
        </div>
    );

    return (
        <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
            <div>
                <div className="font-bold text-4xl text-900 mb-5 text-center">Slider</div>
                <div className="grid nogutter">
                    {datacard}
                </div>
            </div>
        </div>
    )
}

export default Slider