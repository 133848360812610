import React from 'react'

export default function CS_blogPost() {
    return (
        <>
            <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                <div className="grid">
                    <div className="w-full ">
                        <img src="./blog-1.jpg" alt="Image" className="block w-full h-20rem border-round-top" />
                        <div className="p-4">
                            <span className="block font-medium text-blue-600 mb-3">Name </span>
                            <div className="text-xl text-900 font-medium mb-3 line-height-3">Lorem ipsum dolor sit</div>
                            <div className="line-height-3 mb-3 text-700">Consectetur sit aute adipisicing ullamco ex consectetur ut. Anim nostrud anim ullamco nostrud sint. Nostrud commodo culpa excepteur adipisicing dolor deserunt anim ea veniam.</div>
                            <div className="flex">
                                <p-avatar image="web/photos/avatar-f-1.png" shape="circle" className="p-element">
                                    <div className="p-avatar p-component p-avatar-image p-avatar-circle">
                                        <img src="web/photos/avatar-f-1.png" className="ng-star-inserted" />
                                    </div>
                                </p-avatar>
                                <div className="ml-2">
                                    <div className="text-sm font-bold text-900 mb-1">Lorem ipsum </div>
                                    <div className="text-sm flex align-items-center text-700">
                                        <i className="pi pi-calendar mr-1 text-sm"></i><span>Apr 5, 2021</span></div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="mt-5">
                    <ul className="list-none m-0 p-0">
                        <li className="py-5 border-top-1 surface-border">
                            <div className="flex align-items-center justify-content-between mb-3">
                                <div className="flex align-items-center">
                                    <img src="assets/images/blocks/avatars/circle/avatar-m-1.png" className="w-3rem h-3rem flex-shrink-0 mr-3" />
                                    <div className="flex flex-column"><span className="text-900 font-medium text-base mb-1">Person 1</span><span className="text-500 text-xs">February 3, 2022</span></div>
                                </div>

                            </div>
                            <p className="text-600 p-0 m-0 line-height-3">Scelerisque varius morbi enim nunc. Arcu bibendum at varius vel pharetra vel turpis nunc eget. Elit ut aliquam purus sit amet luctus. Aliquam etiam erat velit scelerisque in.</p>
                        </li>
                        <li className="py-5 border-top-1 surface-border">
                            <div className="flex align-items-center justify-content-between mb-3">
                                <div className="flex align-items-center">
                                    <img src="assets/images/blocks/avatars/circle/avatar-f-3.png" className="w-3rem h-3rem flex-shrink-0 mr-3" />
                                    <div className="flex flex-column"><span className="text-900 font-medium text-base mb-1">Person 2</span><span className="text-500 text-xs">February 4, 2022</span></div>
                                </div>

                            </div>
                            <p className="text-600 p-0 m-0 line-height-3">Orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Vitae elementum curabitur vitae nunc sed velit dignissim sodales ut.</p>
                        </li>
                        <li className="py-5 border-top-1 surface-border">
                            <div className="flex align-items-center justify-content-between mb-3">
                                <div className="flex align-items-center">
                                    <img src="assets/images/blocks/avatars/circle/avatar-f-2.png" className="w-3rem h-3rem flex-shrink-0 mr-3" />
                                    <div className="flex flex-column"><span className="text-900 font-medium text-base mb-1">Person 3</span><span className="text-500 text-xs">February 5, 2022</span></div>
                                </div>

                            </div>
                            <p className="text-600 p-0 m-0 line-height-3">Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula. Fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis. </p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
