import { Dialog } from 'primereact/dialog';
import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { useRef } from "react";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { Avatar } from 'primereact/avatar';
import { DataContext } from '../context/DataContext';
import { Hamburger_Icon } from '../controls/Hamburger_Icon';
import CSDropdown from '../controls/CSDropdown';
import CSRequestUtil from '../util/CSRequestUtil';
import CSServiceUtil from '../util/CSServiceUtil';
import CSInputText from '../controls/CSInputText';
import CSDatePicker from '../controls/CSDatePicker';
import CSFormUtil from '../util/CSFormUtil';
import CSFileUpload from '../controls/CSFileUpload';
import mAvatar from '../assets/images/1avatar.png';
export default function C_Navbar(lProps) {
    const mtoast = useRef(null);
    const ProductData = useContext(DataContext);
    const { ProductName, Home, Menu1, Menu2, Menu3, Menu4, Menu5, Menu6, Logo, Color, weburl, Button1, Button2 } = ProductData
    const [mShowNavbar, setShowNavbar] = useState(false);
    const [mImage, setImage] = useState('');
    const [mAcademicYear, setAcademicYear] = useState('');
    const [mViewImage, setViewImage] = useState(mAvatar);
    const [mRegistrationVisible, setRegistrationVisible] = useState(false);
    const handleShowNavbar = () => {
        setShowNavbar(!mShowNavbar);
    };

    //    useEffect(() => {
    //     fnReset();
    //    }, [])

    const [mRegisterData, setRegisterData] = useState({
        mName: '',
        mFatherName: '',
        mDOB: null,
        mInst: null,
        mClass: null,
        mAYyear: null,
        mSection: null,
        mHTNumber: '',
        mPresentAddress: '',
        mPermanentAddress: '',
        mOccupation: '',
        mStatus: '',
        mSpouseName: '',
        mChildName: '',
        mHobbies: '',
        mEmail: '',
        mMobile: '',
        mBloodGroup: '',
        mfavTeacher: '',
        mfavSubject: '',
        mBfriend: '',
        mIntrests: '',
        mAlumniPhoto: '',
        mHtMemo: ''
    })

    const mMarriageStatus = [
        { status: 'Married' },
        { status: 'Un Married' },
    ]
    const mSvbdSection = [
        { section: 'A' },
        { section: 'B' },
        { section: 'C' },
        { section: 'D' }
    ]
    const mSvbdInst = [
        { inst: 'SCHOOL' },
        { inst: 'INTERMEDIATE' },
        { inst: 'DEGREE' },
    ]
    const mSvbdClasses = [
        { grade: 'Class I' },
        { grade: 'Class II' },
        { grade: 'Class III' },
        { grade: 'Class IV' },
        { grade: 'Class V' },
        { grade: 'Class VI' },
        { grade: 'Class VII' },
        { grade: 'Class VIII' },
        { grade: 'Class IX' },
        { grade: 'Class X' },
        { grade: 'Intermediate' },
        { grade: 'Under Graduate' },
    ]
    useEffect(() => {
        const fnGetAyYearList = () => {
            let lServiceCode = 'GET_ACADEMIC_YEAR';
            setShowBackdrop(true);
            let lCriteria = {}
            let lRequestUtil = new CSRequestUtil('GET', lServiceCode, 'academic_year');
            lRequestUtil.cfSetRequestCriteria(lCriteria);
            lRequestUtil.cfProcessRequest((lResponse) => {
                if (lResponse) {
                    if (lResponse.hasOwnProperty('collection_')) {
                        setAcademicYear(lResponse.collection_);
                    }
                    setShowBackdrop(false);
                }
            }
            );
        }

        fnGetAyYearList();
    }, [])

    const fnRegisterData = (lId, lValue) => {
        let lVariable = null;
        let lPhotoFlag = false;
        if (lId === 'name') {
            lVariable = 'mName';
        }
        else if (lId === 'FatherName') {
            lVariable = 'mFatherName';
        }
        else if (lId === 'Dob') {
            lVariable = 'mDOB';
        }
        else if (lId === 'Institution') {
            lVariable = 'mInst';
        }
        else if (lId === 'SelectClass') {
            lVariable = 'mClass';
        }
        else if (lId === 'AY_Year') {
            lVariable = 'mAYyear';
        }
        else if (lId === 'SelectSection') {
            lVariable = 'mSection';
        }
        else if (lId === 'HTNumber') {
            lVariable = 'mHTNumber';
        }
        else if (lId === 'PresentAdd') {
            lVariable = 'mPresentAddress';
        }
        else if (lId === 'PerAdd') {
            lVariable = 'mPermanentAddress';
        }
        else if (lId === 'JOB_DATA') {
            lVariable = 'mOccupation';
        }
        else if (lId === 'MarriageStatus') {
            lVariable = 'mStatus';
        }
        else if (lId === 'SpouseName') {
            lVariable = 'mSpouseName';
        }
        else if (lId === 'ChildName') {
            lVariable = 'mChildName';
        }
        else if (lId === 'Hobbies') {
            lVariable = 'mHobbies';
        }
        else if (lId === 'MailID') {
            lVariable = 'mEmail';
        }
        else if (lId === 'MobileNumber') {
            lVariable = 'mMobile';
        }
        else if (lId === 'BloodGroup') {
            lVariable = 'mBloodGroup';
        }
        else if (lId === 'FAV_TEACHER') {
            lVariable = 'mfavTeacher';
        }
        else if (lId === 'FAV_SUBJECT') {
            lVariable = 'mfavSubject';
        }
        else if (lId === 'BFriend') {
            lVariable = 'mBfriend';
        }
        else if (lId === 'Interests') {
            lVariable = 'mIntrests';
        }
        else if (lId === 'Alumni_Photo') {
            lVariable = 'mAlumniPhoto';
            lPhotoFlag = true;
        }
        else if (lId === 'HT_Image') {
            lVariable = 'mHtMemo';
            lPhotoFlag = true;
        }

        if (!lPhotoFlag) {
            setRegisterData(() => ({
                ...mRegisterData,
                [lVariable]: lValue
            })
            )
        }
        else {
            let lFile = lValue[0];
            if (lFile && lFile.size > 1084576) {
                mtoast.current.show({ severity: 'info', summary: 'Warning', detail: 'Image Size Cannot exceed 1MB' });
            }
            else {
                setImage(lValue);
                setViewImage(URL.createObjectURL(lFile));

                let lTimestamp = lFile.lastModified;
                let lFileName = lTimestamp + lFile.name
                setRegisterData(() => (
                    {
                        ...mRegisterData,
                        [lVariable]: lFileName
                    }
                )
                )
            }
        }
    }
    const [mShowBackdrop, setShowBackdrop] = useState(false);
    const fnhideRegistrationForm = () => {
        if (!mRegistrationVisible)
            return
        setRegistrationVisible(false);
    }
    const [mEnabled, setEnabled] = useState(false)
    const mFormUtil = CSFormUtil.cfGetInstance();

    useEffect(() => {
        const fnEnableSubmit = () => {
            if (
                mRegisterData.mName.length > 0 &&
                mRegisterData.mFatherName.length > 0 &&
                mRegisterData.mDOB &&
                mRegisterData.mInst &&
                mRegisterData.mClass &&
                mRegisterData.mAYyear &&
                mRegisterData.mHTNumber.length > 0 &&
                mRegisterData.mPresentAddress.length > 0 &&
                mRegisterData.mPermanentAddress.length > 0 &&
                mRegisterData.mOccupation.length > 0 &&
                mRegisterData.mStatus &&
                mRegisterData.mEmail.length > 0 &&
                mRegisterData.mMobile.length > 0 &&
                mRegisterData.mfavTeacher.length > 0 &&
                mRegisterData.mfavSubject.length > 0
            ) {
                setEnabled(true)
            }
        }
        fnEnableSubmit();
    }, [mRegisterData])

    let lFiles = mImage
    if (lFiles) {
        let lRequestUtil = new CSRequestUtil();
        lRequestUtil.cfUploadFile(lFiles, null, (lResponseData) => {
            if (lResponseData instanceof Object) {

            }
            else if (lResponseData === -1) {
                // lFileUploadRetVal = false;
            }
        }
        )
    }

    const fnSaveUser = () => {
        let lServiceCode = 'SAVE_ALUMNI_STUDENT_REQUEST';
        if (mRegisterData.mName) {
            let lItem =
            {
                student_name_: mRegisterData.mName,
                father_name_: mRegisterData.mFatherName,
                date_of_birth_: (mRegisterData.mDOB.toLocaleDateString("en-ZA")),
                institute_: (mRegisterData.mInst && (mRegisterData.mInst instanceof Object)) ? mRegisterData.mInst.inst : null,
                class_: (mRegisterData.mClass && (mRegisterData.mClass instanceof Object)) ? mRegisterData.mClass.grade : null,
                academic_year_: (mRegisterData.mAYyear && (mRegisterData.mAYyear instanceof Object)) ? mRegisterData.mAYyear.ay_year : null,
                section_: (mRegisterData.mSection && (mRegisterData.mSection instanceof Object)) ? mRegisterData.mSection.section : null,
                ht_number_: mRegisterData.mHTNumber,
                present_address_: mRegisterData.mPresentAddress,
                permanent_address_: mRegisterData.mPermanentAddress,
                occupation_: mRegisterData.mOccupation,
                marital_status_: (mRegisterData.mStatus && (mRegisterData.mStatus instanceof Object)) ? mRegisterData.mStatus.status : null,
                spouse_name_: mRegisterData.mSpouseName,
                children_names_: mRegisterData.mChildName,
                hobbies_: mRegisterData.mHobbies,
                email_id_: mRegisterData.mEmail,
                mobile_no_: mRegisterData.mMobile,
                blood_group_: mRegisterData.mBloodGroup,
                favorite_teacher_: mRegisterData.mfavTeacher,
                favorite_subject_: mRegisterData.mfavSubject,
                best_friend_: mRegisterData.mBfriend,
                interests_: mRegisterData.mIntrests,
                ht_file_: mRegisterData.mHtMemo,
                photo_file_: mRegisterData.mAlumniPhoto,
                status_ : 'SUBMIT',
            }

            let lRequestUtil = new CSRequestUtil('SAVE', lServiceCode, 'alumni_student_request');
            if (lRequestUtil) {
                lRequestUtil.cfSetRequestData(lItem);
                lRequestUtil.cfProcessRequest((lResponse) => {
                    var lCode = null;
                    var lMsg = null;
                    if (lResponse && lResponse.code) {
                        if (lResponse.code instanceof Array) {
                            lCode = lResponse.code[0];
                            if (lResponse.message) {
                                lMsg = lResponse.message[0];
                            }
                        }
                        else {
                            lCode = lResponse.code;
                            if (lResponse.message) {
                                lMsg = lResponse.message;
                            }
                        }
                        fnhideRegistrationForm();
                    }
                    else {
                        lCode = 'Error in Saving User';

                    }
                });
            }
        }
    }

    const mRegister_form =
        <Dialog
            header={Button2}
            visible={mRegistrationVisible}
            modal={true}
            style={{ width: '70vw' }}
            className='lg:p-0'
            appendTo={document.body}
            headerClassName='p-2 text-gray font-normal text-lg'
            onHide={() => { if (!mRegistrationVisible) return; setRegistrationVisible(false); }}
        >
            <div >
                <CSInputText
                    mvalue={mRegisterData.mName}
                    pOnChange={fnRegisterData}
                    placeholder={' '}
                    pLabel={'Name'}
                    pRequired={true}
                    pLabelId='name'
                    padding='0.5rem 0.75rem'
                    marginBottom='10px' />
                <CSInputText
                    mvalue={mRegisterData.mFatherName}
                    pOnChange={fnRegisterData}
                    placeholder={''}
                    pLabel={'Fathers Name'}
                    pLabelId='FatherName'
                    pRequired={true}
                    padding='0.5rem 0.75rem'
                    marginBottom='10px' />
                <div className='col-12 p-0 flex flex-wrap align-items-center'>
                    <div className='col-12 md:col-4'>
                        <CSDatePicker
                            pLabelId='Dob'
                            pValue={mRegisterData.mDOB}
                            pOnchange={fnRegisterData}
                            pRequired={true}
                            pClassName=''
                        />
                    </div>
                    <div className='col-12 md:col-4 '>
                        <CSInputText
                            mvalue={mRegisterData.mOccupation}
                            pOnChange={fnRegisterData}
                            placeholder={' '}
                            pLabel={'Occupation'}
                            pLabelId='JOB_DATA'
                            pRequired={true}
                            padding='0.5rem 0.75rem'
                            pLabelmb={'30px'}
                        />
                    </div>
                    <div className='col-12 md:col-4'>
                        <CSDropdown
                            pValue={mRegisterData.mStatus}
                            OnchangeValue={fnRegisterData}
                            pOptions={mMarriageStatus}
                            pLabel='status'
                            pRequired={true}
                            pControlLabel='Marital status'
                            pLabelId='MarriageStatus'
                            pWidth='200px'
                        />
                    </div>
                </div>
            </div>
            <div className='flex flex-wrap gap-2'>
                <div className='col-12 md:col-4  p-0 '>
                    <CSInputText
                        mvalue={mRegisterData.mMobile}
                        pOnChange={fnRegisterData}
                        placeholder={' '}
                        pLabel={'Mobile Number'}
                        pLabelId='MobileNumber'
                        width='100%'
                        padding='0.5rem 0.75rem'
                        pRequired={true}
                        marginBottom='10px' />
                    <CSInputText
                        mvalue={mRegisterData.mEmail}
                        pOnChange={fnRegisterData}
                        placeholder={' '}
                        pLabel={'Mail Address'}
                        pLabelId='MailID'
                        pRequired={true}
                        width='100%'
                        padding='0.5rem 0.75rem'
                        marginBottom='10px' />
                </div>
                <div className='col-12 md:col-4  p-0'>
                    <CSInputText
                        mvalue={mRegisterData.mBloodGroup}
                        pOnChange={fnRegisterData}
                        placeholder={' '}
                        pLabel={'Blood Group'}
                        pLabelId='BloodGroup'
                        padding='0.5rem 0.75rem'
                        pRequired={true}
                        marginBottom='10px' />
                    <CSInputText
                        mvalue={mRegisterData.mHobbies}
                        pOnChange={fnRegisterData}
                        placeholder={' '}
                        pLabel={'Present Hobbies'}
                        pLabelId='Hobbies'
                        width='100%'
                        padding='0.5rem 0.75rem'
                        marginBottom='10px' />
                </div>
                <div className='flex col-12 md:col-3 gap-2 align-items-center'>
                    <CSFileUpload
                        pId='Alumni_Photo'
                        pValue={mRegisterData.mAlumniPhoto}
                        pOnChange={fnRegisterData}
                        pImage={mViewImage} />
                </div>
            </div>
            <div className='col-12 p-0'>
                <CSInputText
                    mvalue={mRegisterData.mSpouseName}
                    pOnChange={fnRegisterData}
                    pLabelId='SpouseName'
                    placeholder={' '}
                    pLabel={'Spouse Name'}
                    padding='0.5rem 0.75rem'
                    marginBottom='10px' />
                <CSInputText
                    mvalue={mRegisterData.mChildName}
                    pOnChange={fnRegisterData}
                    pLabelId='ChildName'
                    placeholder={' '}
                    pLabel={'Children Names'}
                    padding='0.5rem 0.75rem'
                    marginBottom='10px' />
                <CSInputText
                    mvalue={mRegisterData.mBfriend}
                    pOnChange={fnRegisterData}
                    placeholder={' '}
                    pLabel={'Your Best Friend'}
                    pLabelId='BFriend'
                    padding='0.5rem 0.75rem'
                    marginBottom='10px' />
                <CSInputText
                    mvalue={mRegisterData.mIntrests}
                    pOnChange={fnRegisterData}
                    placeholder={' '}
                    pLabel={'Interests'}
                    pLabelId='Interests'
                    padding='0.5rem 0.75rem'
                    marginBottom='10px' />
                <CSInputText
                    mvalue={mRegisterData.mPresentAddress}
                    pOnChange={fnRegisterData}
                    placeholder={' '}
                    pLabel={'Present Address'}
                    pLabelId='PresentAdd'
                    pRequired={true}
                    padding='0.5rem 0.75rem'
                    marginBottom='10px' />
                <CSInputText
                    mvalue={mRegisterData.mPermanentAddress}
                    pOnChange={fnRegisterData}
                    placeholder={' '}
                    pLabel={'Permanent Address'}
                    pLabelId='PerAdd'
                    pRequired={true}
                    padding='0.5rem 0.75rem'
                    marginBottom='10px' />
            </div>
            <div className='lg:flex p-2'>
                <CSDropdown
                    pValue={mRegisterData.mClass}
                    OnchangeValue={fnRegisterData}
                    pOptions={mSvbdClasses}
                    pLabel='grade'
                    pRequired={true}
                    pControlLabel='Class'
                    pPlaceHolder='Select a Class'
                    pLabelId='SelectClass'
                    pClassName='col-12 md:col-3 pl-0'
                />

                <CSDropdown
                    pValue={mRegisterData.mInst}
                    OnchangeValue={fnRegisterData}
                    pOptions={mSvbdInst}
                    pLabel='inst'
                    pControlLabel='Inst'
                    pPlaceHolder='Institution'
                    pLabelId='Institution'
                    pRequired={true}
                    pClassName='col-12 md:col-3'
                />
                <CSDropdown
                    pValue={mRegisterData.mSection}
                    OnchangeValue={fnRegisterData}
                    pOptions={mSvbdSection}
                    pRequired={true}
                    pLabel='section'
                    pControlLabel='Section'
                    pPlaceHolder='Section '
                    pLabelId='SelectSection'
                    pClassName='col-12 md:col-3'
                />
                <CSDropdown
                    pValue={mRegisterData.mAYyear}
                    OnchangeValue={fnRegisterData}
                    pOptions={mAcademicYear}
                    pLabel='academic_year_name_'
                    pControlLabel='Year'
                    pRequired={true}
                    pPlaceHolder='Select a Year'
                    pLabelId='AY_Year'
                    pClassName='col-12 md:col-3'
                />
            </div>
            <div className="flex flex-column col-12 p-0">
                <div className='flex align-items-center gap-4 '>
                    <div className='col-12 md:col-6 p-0'>
                        <CSInputText
                            mvalue={mRegisterData.mHTNumber}
                            pOnChange={fnRegisterData}
                            placeholder={''}
                            pLabel={'H T Number on Memos'}
                            pRequired={true}
                            pLabelId='HTNumber'
                            padding='0.5rem 0.75rem' />
                    </div>
                    {/* <div className='col-12 md:col-6 p-0'>
                        <input type="file" />
                        <img src={mRegisterData.mHtMemo} width={'50%'} />
                    </div> */}
                </div>
                <CSInputText
                    mvalue={mRegisterData.mfavTeacher}
                    pOnChange={fnRegisterData} placeholder={' '}
                    pLabel={'Your Favourite Teachers'}
                    pLabelId='FAV_TEACHER'
                    pRequired={true}
                    padding='0.5rem 0.75rem'
                    marginBottom='10px'
                />
                <CSInputText
                    mvalue={mRegisterData.mfavSubject}
                    pOnChange={fnRegisterData} placeholder={' '}
                    pLabelId='FAV_SUBJECT'
                    pRequired={true}
                    pLabel={'Your Favourite Subject'}
                    padding='0.5rem 0.75rem'
                    marginBottom='10px'
                />
            </div>
            <div className="flex pt-4 justify-content-end gap-3 col-12">
                <Button
                    label="Submit"
                    icon="pi pi-check"
                    iconPos="right"
                    onClick={fnSaveUser}
                    style={{ backgroundColor: (!mEnabled) ? 'lightgray' : 'blue' }}
                    disabled={!mEnabled} />
                <Button
                    label="Cancel"
                    severity="secondary"
                    icon="pi pi-times"
                    onClick={() => { if (!mRegistrationVisible) return; setRegistrationVisible(false); }} />

            </div>
        </Dialog>

    return (
        <nav className="navbar">
            <div className="scontainer">
                <Toast ref={mtoast} position="bottom-center" />
                <div className="Logo">
                    <a href="/home" className="p-2 flex align-items-center">
                        <img src={Logo} alt="Logo" height="50" className="mr-0 lg:mr-2" />
                        <span className='font-bold uppercase text-2xl' style={{ color: Color }}>{ProductName}</span>
                    </a>
                </div>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <Hamburger_Icon />
                </div>
                <div className={`nav-elements  ${mShowNavbar && "active"}`}>
                    <ul>
                        <li>
                            <NavLink to="/">{Home}</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/about">{Menu1}</NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/gallery">{Menu2}</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/blogs">{Menu3}</NavLink>
                        </li> */}
                        {/* <li>
                            <NavLink to="/admin">{Menu4}</NavLink>
                        </li>  */}
                        <li>
                            <NavLink to="/contact">{Menu5}</NavLink>
                        </li>
                        <li className='flex flex-wrap justify-content-center gap-2'>
                            <a href="https://www.sbvds.org/sbvds.html" target="_blank" rel="noopener noreferrer" className="p-button w-6rem h-3rem text-center surface-500 justify-content-center text-white border-round-lg border-3">{Button1}</a>
                            <Button
                                label={Button2}
                                icon="pi pi-user"
                                className='w-8rem h-3rem bg-primary text-white border-round-lg border-3 justify-content-center text-center  lg:block'
                                style={{ padding: '12px 14px 14px 14px' }}
                                onClick={() => setRegistrationVisible(true)} >
                            </Button>
                            {mRegister_form}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}