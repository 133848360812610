import React, { useRef, useState } from 'react'
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Navigate, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
export default function CS_Blog() {

    const [mvalue, setmValue] = useState('');
    const [mBlogShow, setBlogShow] = useState(false);
    const blogData = [
        {

            id: 1001,
            category: 'Technology',
            image: '1.jpg',
            text: 'Occaecat deserunt sunt eu eu amet ut dolor. Eiusmod anim nulla eu voluptate iruis eu id voluptate et laborum. Exercitation ut fugiat anim ex',
            author: 'Name1',
        },
        {
            id: 1002,
            category: 'Art',
            image: '2.jpg',
            text: ' nostrud occaecat duis. Cillum eu id voluptate et laborum. Exercitation ut fugiat anim ex uExercitation ut fugiat anim ex ut.',
            author: 'Name2',
        },
        {
            id: 1003,
            category: 'Dynamo',
            image: '3.jpg',
            text: ' Eiusmod anim nulla eu voluptate irure ea nostrud occaecat duis. Cillum eu id voluptate et laborum. Exercitation ut fugiat anim ex ut.',
            author: 'Name3',
        },
        {
            id: 1004,
            category: 'sample',
            image: '3.jpg',
            text: '  eu voluptate irure ea nostrud occaecat duis. Cillum eu id voluptate et laborum. Exercitation ut fugiat anim ex ut.',
            author: 'Name4',
        }

    ];
    const [mblogData, setBlogData] = useState(blogData);
    const lnavigate = useNavigate()
    const mtoast = useRef(null);
    const onUpload = () => {
        mtoast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    function OpenBlog() {
        lnavigate("/blogpost")
    }

    const blogCard = mblogData.map((pblog) =>
        <div className="col-12 lg:col-4 p-3" key={pblog.id} onClick={OpenBlog}>
            <div className="shadow-2 border-round h-full surface-card">
                <img src="web/photos/blog-1.jpg" alt="Image" className="block w-full border-round-top" />
                <div className="p-4">
                    <span className="block font-medium text-blue-600 mb-3">{pblog.author} </span>
                    <div className="text-xl text-900 font-medium mb-3 line-height-3">Lorem ipsum dolor sit</div>
                    <div className="line-height-3 mb-3 text-700">{pblog.text}</div>
                    <div className="flex">
                        <p-avatar image="web/photos/avatar.png" shape="circle" className="p-element">
                            <div className="p-avatar p-component p-avatar-image p-avatar-circle">
                                <img src="web/photos/avatar.png" className="w-2rem ng-star-inserted" />
                            </div>
                        </p-avatar>
                        <div className="ml-2">
                            <div className="text-sm font-bold text-900 mb-1">Lorem ipsum</div>
                            <div className="text-sm flex align-items-center text-700">
                                <i className="pi pi-calendar mr-1 text-sm"></i><span>Apr 5, 2021</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    const mActions =
        <div className='p-2'>
            <Button label="Cancel" icon="pi pi-times" className='w-6rem mr-2 bg-primary-light' />
            <Button label="Post" icon="pi pi-check" className='w-6rem' />
        </div>
    const mCreateBlog =
        <Dialog visible={mBlogShow} header='Post a Blog' onHide={() => { if (!mBlogShow) return; setBlogShow(false); }} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} footer={mActions}>
            <div className="surface-card border-round  p-1" >
                <InputTextarea value={mvalue} onChange={(e) => setmValue(e.target.value)} rows={5} cols={30} className='w-full'>
                </InputTextarea>
                <FileUpload mode="basic"  url="/api/upload" accept="image/*" className='mb-1' maxFileSize={1000000} onUpload={onUpload} chooseLabel="Choose Photo" />
            </div>
        </Dialog>

    const pageBanner =
        <div className="surface-0">
            <ul className="list-none p-2 m-0 flex align-items-center font-medium mb-3">
                <li>
                    <a className="text-500 no-underline line-height-3 cursor-pointer">Blogs</a>
                </li>
                <li className="px-2">
                    <i className="pi pi-angle-right text-500 line-height-3"></i>
                </li>
                <li>
                    <span className="text-900 line-height-3">Post a Blog</span>
                </li>
            </ul>
            <div className="flex align-items-start p-2 flex-column lg:justify-content-between lg:flex-row">
                <div>
                    <div className="font-medium text-3xl text-900">Students</div>
                    <div className="flex align-items-center text-700 flex-wrap">
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-users mr-2"></i>
                            <span>20 Active Students</span>
                        </div>
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-globe mr-2"></i>
                            <span>40 Blogs</span>
                        </div>
                        <div className="flex align-items-center mt-3">
                            <i className="pi pi-users mr-2"></i>
                            <span>550 Registerd Students</span>
                        </div>
                    </div>
                </div>
                <div className="mt-3 lg:mt-0">
                    <Button label="Post A Blog" className="p-button-outlined mr-2" icon="pi pi-plus" onClick={() => setBlogShow(true)} />
                </div>
            </div>
        </div>


    return (
        <div>
            <div className="surface-ground px-4 py-4 md:px-6 lg:px-4">
                <div className="font-bold text-5xl text-900 mb-5 text-center">Blogs</div>
                {pageBanner}
                {mCreateBlog}
                <div className="grid nogutter">
                    {blogCard}
                </div>
            </div>
        </div>
    )
}
