import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import CSServiceUtil from '../util/CSServiceUtil';
import CSRequestUtil from '../util/CSRequestUtil';
import CSCOBackdrop from '../controls/CSCOBackdrop';
export default function C_Admin() {
  const [mUservisible, setUserVisible] = useState(false);
  const [mblogvisible, setBlogVisible] = useState(false);
  const [mAddMantra, setAddMantra] = useState('');
  const [mShowBackdrop, setShowBackdrop] = useState(false);
  const [mAllAlumni, setAllAlumni] = useState([]);

  useEffect(() => {
    fnGetAllAlumni();
  }, []
  );

  const fnGetAllAlumni = () => {
    let lServiceCode = 'GET_ALUMNI_STUDENT_REQUEST';
    let lServiceObject = (CSServiceUtil.cfGetInstance()).cfGetServiceObject(lServiceCode);
    if (lServiceObject) {
      setShowBackdrop(true);
      let lCriteria = {}
      let lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_);
      lRequestUtil.cfSetRequestCriteria(lCriteria);
      lRequestUtil.cfProcessRequest((lResponse) => {
        if (lResponse) {
          if (lResponse.hasOwnProperty('collection_')) {
            setAllAlumni(lResponse.collection_);
          }
          setShowBackdrop(false);
        }
      }
      );
    }
  }

  const ufooterContent = (
    <div className='p-2'>
      <Button label="No" icon="pi pi-times" onClick={() => setUserVisible(false)} className="p-button-text" />
      <Button label="Yes" icon="pi pi-check" onClick={() => setUserVisible(false)} autoFocus />
    </div>
  );
  const bfooterContent = (
    <div className='p-2'>
      <Button label="No" icon="pi pi-times" onClick={() => setBlogVisible(false)} className="p-button-text" />
      <Button label="Yes" icon="pi pi-check" onClick={() => setBlogVisible(false)} autoFocus />
    </div>
  );

  const mUserAccept =
    <Dialog header="Blog" style={{ width: '30vw' }} visible={mUservisible} onHide={() => { if (!mUservisible) return; setUserVisible(false); }} footer={ufooterContent} >
      <div className='justify-content-center align-items-center'>
        <div className=" text-blue-600 inline-flex justify-content-center align-items-center mb-4">
          <Image src="./blog-1.jpg" alt="Image" width="350" />
        </div>
        <div className="text-900 font-medium text-2xl mb-1">New Blog</div>

        <div className="text-700 mb-4 line-height-3">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
      </div>
    </Dialog>

  const mBlogAccept =
    <Dialog header="User Details" style={{ width: '30vw' }} visible={mblogvisible} onHide={() => { if (!mblogvisible) return; mblogvisible(false); }} footer={bfooterContent} >
      <div className='justify-content-center align-items-center'>
        <div className="border-circle bg-blue-50 p-4 text-blue-600 inline-flex justify-content-center align-items-center mb-4">
          <Image src="web/photos/avatar.png" alt="Image" width="100" />
        </div>
        <div className="text-900 font-medium text-2xl mb-1">Ramachandra</div>
        <ul className='w-full block'>
          <li className="text-700 font-medium text-xl ">1998 Batch</li>
          <li className="text-700 font-medium text-xl mb-3">432425 sadasdsad, snjdasid</li>
        </ul>
        <div className="text-700 mb-4 line-height-3">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
      </div>
    </Dialog>
  return (
    <div className="p-5 flex flex-column flex-auto">
      <div className="grid">
        {mUserAccept}
        {mBlogAccept}
        <div className="col-12 md:col-6 lg:col-6">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">Students</span>
                <div className="text-900 font-medium text-xl">200</div>
              </div>
              <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                <i className="pi pi-user text-blue-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">24 new </span>
            <span className="text-500">since last visit</span>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-6">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">Blogs</span>
                <div className="text-900 font-medium text-xl">152 </div>
              </div>
              <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                <i className="pi pi-comment text-purple-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">10 </span>
            <span className="text-500">New</span>
          </div>
        </div>
        <div className="col-12 lg:col-4">
          <div className="surface-card shadow-2 border-round p-4 h-full">
            <div className="flex align-items-center justify-content-between mb-3">
              <div className="text-900 font-medium text-xl">New User Request</div>
              {/* <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                <i className="pi pi-user text-green-500 text-xl"></i>
              </div> */}
            </div>
            <ul className="list-none p-2 m-0">
              {
                mAllAlumni.map((alumni) => (
                  <li className="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row">
                    <div className="flex align-items-start mr-0 lg:mr-5">
                      <img src="web/photos/avatar.png" alt="avatar-f-1" className="mr-3 w-3rem h-3rem" />
                      <div>
                        <span className="text-900 font-medium block mb-2">alumni.student_name_</span>
                        <div className="text-700 mb-2">academic_year_</div>
                      </div>
                    </div>
                    <Button onClick={() => setUserVisible(true)} icon="pi pi-check" label="Details" />
                  </li>
                )
                )
              }
            </ul>
          </div>
        </div>
        <div className="col-12 lg:col-4">
          <div className="surface-card shadow-2 border-round p-4 h-full">
            <div className="flex align-items-center justify-content-between mb-3">
              <div className="text-900 font-medium text-xl">Blog Approve Request</div>
              {/* <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                <i className="pi pi-user text-green-500 text-xl"></i>
              </div> */}
            </div>
            <ul className="list-none p-2 m-0">
              <li className="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row">
                <div className="flex align-items-start mr-0 lg:mr-5">
                  <img src="web/photos/avatar.png" alt="avatar-f-1" className="mr-3 w-3rem h-3rem" />
                  <div>
                    <span className="text-900 font-medium block mb-2">Ramachandra Posted a Blog</span>
                  </div>
                </div>
                <Button onClick={() => setUserVisible(true)} icon="pi pi-check" label="Details" />
              </li>

            </ul>
          </div>
        </div>
        <div className="col-12 lg:col-4">
          <div className="surface-card shadow-2 border-round p-4 h-full">
            <div className="flex align-items-center justify-content-between mb-4">
              <div className="text-900 font-medium text-xl">Notifications</div>
            </div>
            <span className="block text-600 font-medium mb-3">TODAY</span>
            <ul className="p-0 mx-0 mt-0 mb-4 list-none">
              <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                  <Image src="web/photos/avatar.png" alt="Image" width="50" />
                </div>
                <span className="text-900 line-height-3 font-medium">Ramachandra<span className="text-700 font-normal"> posted a Blog </span></span>
              </li>
              <li className="flex align-items-center py-2">
                <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0">
                  <Image src="./avatar2.png" alt="Image" width="40" />
                </div>
                <span className="text-900 line-height-3 font-medium">Krishna Chaitanya<span className="text-700 font-normal"> Commented Ramachandra Post</span></span>
              </li>
            </ul>
            <span className="block text-600 font-medium mb-3">YESTERDAY</span>

          </div>
        </div>



      </div>
    </div>
  )
}
