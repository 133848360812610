import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import App from './App';
import { ConfigProvider } from 'antd';
ReactDOM.render(
  < BrowserRouter>
    <PrimeReactProvider>
    <ConfigProvider theme={{ cssVar: true }}>
      <App />
    </ConfigProvider>
    </PrimeReactProvider>
  </BrowserRouter>,
  document.getElementById('root')
);