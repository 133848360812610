import { InputText } from 'primereact/inputtext'
import React from 'react'
import { InputTextarea } from "primereact/inputtextarea";
import { useState } from 'react';
function CS_Contact() {
    const [value, setValue] = useState('');
    return (
        <div >
            <div className="col-12 text-center  ">
                {/* <img src="./cs_logo_latest.png" alt="sample" height="50" className="mb-3" /> */}
                <div className="text-900 text-3xl font-bold ">Contact Us</div>
                <div className='csunderline'></div>
            </div>
            <div className="col-12 surface-card shadow-3 py-3 px-8 flex flex-wrap justify-content-between">
                <div className='col-12 lg:col-6'>
                <div className="text-xl font-bold line-height-3 mb-3 ">GET IN TOUCH</div>
                <div className="text-700 font-medium line-height-3 text-justify mb-5 pr-8">We look forward to hearing from you! Whether you have questions, feedback, insterest in donating to the institution or would like to learn more about our institution, we are here to assist you. Please feel free to reach out to us through any of the means mentioned here.
                </div>
                <div className="block font-bold p-1 border-round ">
                    <h3 className="flex align-items-center "><i className="pi pi-phone mr-3"></i><span>+91-7989049707</span></h3>
                </div>
                <div className="block font-bold p-1 border-round ">
                    <h3 className="flex align-items-center "><i className="pi pi-inbox mr-3"></i><span>sbvdsgroup@gmail.com</span></h3>
                </div>
                <div className="block font-bold p-1 border-round ">
                    <h3 className="flex align-items-center"><i className="pi pi-directions mr-3"></i><span>Pullampet, Andhra Pradesh. 516107</span></h3>
                </div>
                </div>
                <div className="col-12 lg:col-6" >
                    <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=SBVD%20Sabha%20High%20School%20&amp;%20College+(SBVDS)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps vehicle tracker</a></iframe>

                </div>
            </div>
            <div className="h-4rem align-items-center px-8 bg-primary align-items-center text-white">
                <div className="flex flex-column sm:flex-row align-items-center justify-content-center lg:py-1  ">
                    <div className="flex mt-1 sm:mt-0 align-items-center pr-8"><img src="web/photos/logo.png" alt="Image" height="40" /><span className='font-bold text-xl ml-3'>SBVDS School</span></div>

                    <div className="flex flex-nowrap font-medium pl-8">
                        <p>Copyright &copy; 2023, SBVDS Institutions. All Rights Reserved.</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CS_Contact