import React from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

class CSCOBackdrop  extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            mShow   : this.props.pShow
        }
    }

    componentDidMount()
    {
        this.setState({ mShow  : this.props.pShow })
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(lPrevProps.pShow !== this.props.pShow)
            {
                this.setState({ mShow  : this.props.pShow })
            }
        }
    }

    render()
	{
        return(
            <>
                <Backdrop  style={{zIndex:'99'}} open={this.state.mShow}>
                    <CircularProgress color="primary" />
                </Backdrop>
            </>
        )
    }
}

export default CSCOBackdrop;
