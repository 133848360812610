import React, { useState } from 'react'
import { SelectButton } from 'primereact/selectbutton';
import { classNames } from 'primereact/utils';
import {CSImages} from '../data/PhotosData'
import { Image } from 'primereact/image';

export default function CS_Gallery() {
    const icon = (<i className="pi pi-search"></i>)
    const [mImagesData, setImagesData] = useState(CSImages);
    const [mGalleryIcon, setGalleryIcon] = useState(null);
    const mPhotos = mImagesData.map((cimage) =>
        <div id='1' className='col-12 lg:col-3 md:col-3 justify-content-center'>
            <div key={cimage.id}>
            <Image src={cimage.url} alt="photo" indicatorIcon={icon} className='border-round-xl' width='100%' preview loading='lazy'/>
            </div>
        </div>
    );
    const mVideos = mImagesData.map((cimage) =>
        <div id='2' className="col-12 lg:col-2 md:col-2  p-3" >
            <img className="border-round" src={cimage.imageurl} alt='video' />
        </div>
    );
    const [selectedOption, setSelectedOption] = useState(mPhotos);

    const options = [
        { id: 10001, icon: 'pi pi-image', value: mPhotos, label: 'Photos' },
        { id: 10002, icon: 'pi pi-video', value: mVideos, label: 'Videos' }
    ];
    const [mGalleryTitle, setGalleryTitle] = useState('Photos');

    const justifyTemplate = (option) => {
        return <i key={option.id} className={option.icon}></i>;
    }
    const handleOptionChange = (e) => {
        if (e.value === 1) {
            setGalleryTitle('Photos');
            setSelectedOption(mPhotos);
        }
        else {
            setGalleryTitle('Videos');
            setSelectedOption(mVideos);
        }
    };

    return (
        <div>
            <div className='flex justify-content-end'>
                <SelectButton
                    value={selectedOption}
                    options={options}
                    optionValue='id'
                    onChange={handleOptionChange}
                    itemTemplate={justifyTemplate}
                />
            </div>
            <div className="grid px-6">
                <div className='w-full'>
                    <div className='text-2xl text-primary font-bold mb-3'> {mGalleryTitle}</div>
                </div>
                <div className='flex flex-wrap p-1 '>
                {selectedOption}
                </div>
            </div>
        </div>
    );
};