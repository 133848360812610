import CSFormUtil from "./CSFormUtil";
import CSRequestUtil from "./CSRequestUtil";

class CSServiceUtil
{
    mServiceUtil = null;
    mServiceList = null;
    mRequestDefinitionList = null;
    mRequestIdObjectMap = null;
    mServiceCodeObjectMap = {};
    mDataProviderList = {};
    mDataProviderObjectMap = {};
    mCriteriaDefinitionList = {};
    mCriteriaNameObjectMap = {};
    mDataProviderIdCallBackMap = {};
    mDataProviderIdListMap = {};

    static cfGetInstance()
    {
        if(this.mServiceUtil === undefined)
        {
            this.mServiceUtil = new CSServiceUtil();
        }

        return this.mServiceUtil;
    }

    cfInitializeService(lServiceList)
    {
        if(lServiceList != null)
        {
            this.mServiceList = lServiceList;
            for(var ind=0; ind<lServiceList.length; ind++)
            {
                var lServiceObject = lServiceList[ind];
                this.mServiceCodeObjectMap[lServiceObject.code_] = lServiceObject;
            }
        }

        return this.mServiceCodeObjectMap;
    }

    cfGetServiceObject(lServiceCode)
    {
        var lServiceObject = null;

        if(lServiceCode != null)
        {
            lServiceObject = this.mServiceCodeObjectMap[lServiceCode];
        }

        return lServiceObject;
    }

    cfInitializeRequestDefinition(lRequestList)
    {
        if(lRequestList != null)
        {
            this.mRequestIdObjectMap = {};
            this.mRequestDefinitionList = lRequestList;
            for(var ind=0; ind<lRequestList.length; ind++)
            {
                var lRequestObject = lRequestList[ind];
                this.mRequestIdObjectMap[lRequestObject.id_] = lRequestObject;
            }
        }

        return this.mRequestIdObjectMap;
    }

    cfGetRequestObject(lRequestRefId)
    {
        var lRequestObject = null;

        if(lRequestRefId && this.mRequestIdObjectMap)
        {
            lRequestObject = this.mRequestIdObjectMap[lRequestRefId];
        }

        return lRequestObject;
    }

    cfInitializeDataProvider(lDataProviderList)
    {
        if(lDataProviderList != null)
        {
            this.mDataProviderList = lDataProviderList;
            for(var ind=0; ind<lDataProviderList.length; ind++)
            {
                var lDataProviderObject = lDataProviderList[ind];
                this.mDataProviderObjectMap[lDataProviderObject.data_provider_id_] = lDataProviderObject;
            }     
        }
    }

    cfGetDataProviderObject(lDataProviderId)
    {
        var lRetValue = null;
        if(lDataProviderId)
        {
            lRetValue = this.mDataProviderObjectMap[lDataProviderId];
        }

        return lRetValue;
    }
    
    cfGetDataFromDataProvider(lDataProviderId, fHandleResponse, reload = false)
    {
        var lServiceUtil = CSServiceUtil.cfGetInstance();
        var lDataProvider = null;
        if((lDataProviderId) && (fHandleResponse))
        {
            var lDataProviderList = lServiceUtil.mDataProviderIdListMap[lDataProviderId];
            if(!lDataProviderList || reload)
            {
                lServiceUtil.mDataProviderIdCallBackMap[lDataProviderId] = fHandleResponse;
                var lDataProviderObject = lServiceUtil.mDataProviderObjectMap[lDataProviderId];
                if(lDataProviderObject && (lDataProviderObject.service_code_ != null))
                {
                    var lServiceObject = lServiceUtil.cfGetServiceObject(lDataProviderObject.service_code_);
                    if(lServiceObject)
                    {
                        var lRequestUtil = new CSRequestUtil('GET', lDataProviderObject.service_code_, lServiceObject.type_);
                        if((lDataProviderObject.param_name_) || lServiceObject.hasOwnProperty('cs_service_properties_'))
                        {
                            var criteria_obj = {};
                            if(lDataProviderObject.param_name_)
                            {
                                criteria_obj[lDataProviderObject.param_name_] = CSFormUtil.cfGetConstantValue(lDataProviderObject.param_value_);
                            }
                            else if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                            {
                                var lServiceProperties = lServiceObject.cs_service_properties_;
                                for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                                {
                                    criteria_obj[lServiceProperties[lInd].property_] = lServiceProperties[lInd].value_;
                                }
                            }

                            var lUserId = criteria_obj['user_id_'];
                            if(!lUserId)
                            {
                                criteria_obj['user_id_'] = CSRequestUtil.mUserId;
                            }

                            lRequestUtil.cfSetRequestCriteria(criteria_obj);
                        }

                        var lRequestKeys = {};
                        lRequestKeys.data_provider_id_ = lDataProviderId;
                        lRequestUtil.cfProcessRequest((lResponse, lCriteria)=>
                            {
                                lServiceUtil.cfProcessResponse(lResponse, lRequestKeys);
                            }
                        );
                    }
                }
            }
            else
            {
                fHandleResponse(lDataProviderId, lDataProviderList);
            }
        }

        return lDataProvider;
    }

    cfProcessResponse(lResponse, lRequestKeys)
    {
        var lServiceUtil = CSServiceUtil.cfGetInstance();
        if((lResponse) && (lServiceUtil.mDataProviderIdCallBackMap))
        {
            if(lRequestKeys)
            {
                var lDataProviderId = lRequestKeys.data_provider_id_;
                if(lDataProviderId != null)
                {
                    var lCallBackFunction = lServiceUtil.mDataProviderIdCallBackMap[lDataProviderId];
                    if(lCallBackFunction && lResponse.hasOwnProperty('collection_'))
                    {
                        lServiceUtil.mDataProviderIdListMap[lDataProviderId] = lResponse.collection_;
                        lCallBackFunction(lDataProviderId, lResponse.collection_);
                    }
                }
            }
        }
    }

    cfInitializeCriteriaDefinition(lCriteriaList)
    {
        if(lCriteriaList != null)
        {
            this.mCriteriaDefinitionList = lCriteriaList;
            for(var ind=0; ind<lCriteriaList.length; ind++)
            {
                var lCriteriaDefinitionObject = lCriteriaList[ind];
                this.mCriteriaNameObjectMap[lCriteriaDefinitionObject.name_] = lCriteriaDefinitionObject;
            }     
        }
    }

    cfGetCriteriaDefinitionObject(lCriteriaName)
    {
        var lRetValue = null;
        if(lCriteriaName)
        {
            lRetValue = this.mCriteriaNameObjectMap[lCriteriaName];
        }

        return lRetValue;
    }

    cfGetFormMetaData(lCriteria, lCallBackFunction)
    {
        var lRequestUtil = new CSRequestUtil(
            'GET', 
            'CS_REQ_GET_CS_SERVICE_DETAILS', 
            'cs_service', 
            'CS_REQ_GET_CS_SERVICE_DETAILS');

        lCriteria.call_back_function_ = lCallBackFunction;
        lRequestUtil.cfSetRequestCriteria(lCriteria);
        lRequestUtil.cfProcessRequest(this.cfProcessMetaDataResponse);
    }

    cfProcessMetaDataResponse(lData, lCriteria)
    {
        if(lData && lCriteria && lCriteria.hasOwnProperty('call_back_function_'))
        {
            var lResponseObj = {}
            lResponseObj.pServices = [];
            lResponseObj.pFormCriteria = [];
            lResponseObj.pFormGrid = [];
            lResponseObj.pFormItems = [];
            lResponseObj.pFormActions = [];
            lResponseObj.pFormDetails = null;
            lResponseObj.pFormProperties = null;
            lResponseObj.pFormValidation = null;
            lResponseObj.pFormSelection = null;
            lResponseObj.pFormActionProperties = null;
            lResponseObj.pFormCriteriaProperties = null;
            lResponseObj.pFormGridProperties = null;
            lResponseObj.pFormGridItemProperties = null;
            lResponseObj.pFormItemProperties = null;
            lResponseObj.pFormItemComputation = null;
            lResponseObj.mActionRefIdFormItemObjectMap = {};
            lResponseObj.mFormItemRefIdObjectMap = {};
            lResponseObj.mControlIdFormItemObjectMap = {};

            if(lData.hasOwnProperty('cs_service_'))
            {
                if(lData.cs_service_ instanceof Array)
                {
                    lResponseObj.pServices = lData.cs_service_;
                }
                else
                {
                    lResponseObj.pServices.push(lData.cs_service_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_criteria_'))
            {
                if(lData.cs_form_criteria_ instanceof Array)
                {
                    lResponseObj.pFormCriteria = lData.cs_form_criteria_;
                }
                else
                {
                    lResponseObj.pFormCriteria.push(lData.cs_form_criteria_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_grid_'))
            {
                if(lData.cs_form_grid_ instanceof Array)
                {
                    lResponseObj.pFormGrid = lData.cs_form_grid_;
                }
                else
                {
                    lResponseObj.pFormGrid.push(lData.cs_form_grid_);
                }
            }
                        
            if(lData.hasOwnProperty('cs_form_items_'))
            {
                if(lData.cs_form_items_ instanceof Array)
                {
                    lResponseObj.pFormItems = lData.cs_form_items_;
                }
                else
                {
                    lResponseObj.pFormItems.push(lData.cs_form_items_);
                }
            }
                        
            if(lData.hasOwnProperty('cs_form_details_'))
            {
                if(lData.cs_form_details_ instanceof Array)
                {
                    lResponseObj.pFormDetails = lData.cs_form_details_;
                }
                else
                {
                    lResponseObj.pFormDetails = [];
                    lResponseObj.pFormDetails.push(lData.cs_form_details_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_actions_'))
            {
                if(lData.cs_form_actions_ instanceof Array)
                {
                    lResponseObj.pFormActions = lData.cs_form_actions_;
                }
                else
                {
                    lResponseObj.pFormActions.push(lData.cs_form_actions_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_properties_'))
            {
                if(lData.cs_form_properties_ instanceof Array)
                {
                    lResponseObj.pFormProperties = lData.cs_form_properties_;
                }
                else
                {
                    lResponseObj.pFormProperties = [];
                    lResponseObj.pFormProperties.push(lData.cs_form_properties_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_validation_'))
            {
                if(lData.cs_form_validation_ instanceof Array)
                {
                    lResponseObj.pFormValidation = lData.cs_form_validation_;
                }
                else
                {
                    lResponseObj.pFormValidation = [];
                    lResponseObj.pFormValidation.push(lData.cs_form_validation_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_selection_'))
            {
                if(lData.cs_form_selection_ instanceof Array)
                {
                    lResponseObj.pFormSelection = lData.cs_form_selection_;
                }
                else
                {
                    lResponseObj.pFormSelection = [];
                    lResponseObj.pFormSelection.push(lData.cs_form_selection_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_action_properties_'))
            {
                if(lData.cs_form_action_properties_ instanceof Array)
                {
                    lResponseObj.pFormActionProperties = lData.cs_form_action_properties_;
                }
                else
                {
                    lResponseObj.pFormActionProperties = [];
                    lResponseObj.pFormActionProperties.push(lData.cs_form_action_properties_);
                }
            }

            for(var lFaInd=0; lFaInd<lResponseObj.pFormActions.length; lFaInd++)
            {
                var lFormActionObj = lResponseObj.pFormActions[lFaInd];
                if(lFormActionObj && lFormActionObj.id_)
                {
                    CSFormUtil.gActionIdObjMap[lFormActionObj.id_] = lFormActionObj;
                    if(lResponseObj.pFormActionProperties)
                    {
                        for(var lFapInd=0; lFapInd<lResponseObj.pFormActionProperties.length; lFapInd++)
                        {
                            var lFapObj = lResponseObj.pFormActionProperties[lFapInd];
                            if(lFormActionObj.id_ === lFapObj.cs_form_action_ref_id_)
                            {
                                var lFapCol = lFormActionObj.cs_form_action_properties_;
                                if(!lFapCol)
                                {
                                    lFapCol = [];
                                    lFormActionObj.cs_form_action_properties_ = lFapCol;
                                }
                                
                                lFapCol.push(lFapObj);
                            }
                        }
                    }

                    if(lResponseObj.pFormValidation)
                    {
                        for(var lFvInd=0; lFvInd<lResponseObj.pFormValidation.length; lFvInd++)
                        {
                            var lFvObj = lResponseObj.pFormValidation[lFvInd];
                            var lFvCol = null;
                            if(lFvObj.action_ && (!lFvObj.cs_form_action_ref_id_) && (!lFvObj.cs_form_item_ref_id_))
                            {
                                lFvCol = lFormActionObj.cs_form_validation_;
                                if(!lFvCol)
                                {
                                    lFvCol = [];
                                    lFormActionObj.cs_form_validation_ = lFvCol;
                                }
                                
                                lFvCol.push(lFvObj);
                            }
                            else if(lFormActionObj.id_ === lFvObj.cs_form_action_ref_id_)
                            {
                                lFvCol = lFormActionObj.cs_form_validation_;
                                if(!lFvCol)
                                {
                                    lFvCol = [];
                                    lFormActionObj.cs_form_validation_ = lFvCol;
                                }
                                
                                lFvCol.push(lFvObj);
                            }
                        }
                    }
                }
            }

            if(lResponseObj.pFormCriteria && lData.hasOwnProperty('cs_form_criteria_properties_'))
            {
                if(lData.cs_form_criteria_properties_ instanceof Array)
                {
                    lResponseObj.pFormCriteriaProperties = lData.cs_form_criteria_properties_;
                }
                else
                {
                    lResponseObj.pFormCriteriaProperties = [];
                    lResponseObj.pFormCriteriaProperties.push(lData.cs_form_criteria_properties_);
                }

                if(lResponseObj.pFormCriteriaProperties.length > 0)
                {
                    for(var lFcInd=0; lFcInd<lResponseObj.pFormCriteria.length; lFcInd++)
                    {
                        var lFormCriteriaObj = lResponseObj.pFormCriteria[lFcInd];
                        for(var lFcpInd=0; lFcpInd<lResponseObj.pFormCriteriaProperties.length; lFcpInd++)
                        {
                            var lFcpObj = lResponseObj.pFormCriteriaProperties[lFcpInd];
                            if(lFormCriteriaObj.id_ === lFcpObj.cs_form_criteria_ref_id_)
                            {
                                var lFcpCol = lFormCriteriaObj.cs_form_criteria_properties_;
                                if(!lFcpCol)
                                {
                                    lFcpCol = [];
                                    lFormCriteriaObj.cs_form_criteria_properties_ = lFcpCol;
                                }
                                
                                lFcpCol.push(lFcpObj);
                            }
                        }
                    }
                }
            }
            
            if(lData.hasOwnProperty('cs_form_grid_properties_'))
            {
                if(lData.cs_form_grid_properties_ instanceof Array)
                {
                    lResponseObj.pFormGridProperties = lData.cs_form_grid_properties_;
                }
                else
                {
                    lResponseObj.pFormGridProperties = [];
                    lResponseObj.pFormGridProperties.push(lData.cs_form_grid_properties_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_grid_item_properties_'))
            {
                if(lData.cs_form_grid_item_properties_ instanceof Array)
                {
                    lResponseObj.pFormGridItemProperties = lData.cs_form_grid_item_properties_;
                }
                else
                {
                    lResponseObj.pFormGridItemProperties = [];
                    lResponseObj.pFormGridItemProperties.push(lData.cs_form_grid_item_properties_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_item_properties_'))
            {
                if(lData.cs_form_item_properties_ instanceof Array)
                {
                    lResponseObj.pFormItemProperties = lData.cs_form_item_properties_;
                }
                else
                {
                    lResponseObj.pFormItemProperties = [];
                    lResponseObj.pFormItemProperties.push(lData.cs_form_item_properties_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_item_computation_'))
            {
                if(lData.cs_form_item_computation_ instanceof Array)
                {
                    lResponseObj.pFormItemComputation = lData.cs_form_item_computation_;
                }
                else
                {
                    lResponseObj.pFormItemComputation = [];
                    lResponseObj.pFormItemComputation.push(lData.cs_form_item_computation_);
                }
            }

            for(var lFgiInd=0; lFgiInd<lResponseObj.pFormGrid.length; lFgiInd++)
            {
                var lFormGridObj = lResponseObj.pFormGrid[lFgiInd];
                if(lResponseObj.pFormGridItemProperties)
                {
                    for(var lFgipInd=0; lFgipInd<lResponseObj.pFormGridItemProperties.length; lFgipInd++)
                    {
                        var lFgipObj = lResponseObj.pFormGridItemProperties[lFgipInd];
                        if(lFormGridObj.id_ === lFgipObj.cs_form_grid_ref_id_)
                        {
                            var lFgipCol = lFormGridObj.cs_form_grid_item_properties_;
                            if(!lFgipCol)
                            {
                                lFgipCol = [];
                                lFormGridObj.cs_form_grid_item_properties_ = lFgipCol;
                            }
                            
                            lFgipCol.push(lFgipObj);
                        }
                    }
                }
            }

            for(var lFiInd=0; lFiInd<lResponseObj.pFormItems.length; lFiInd++)
            {
                var lFormItemObj = lResponseObj.pFormItems[lFiInd];
                lResponseObj.mFormItemRefIdObjectMap[lFormItemObj.id_] = lFormItemObj;
                lResponseObj.mControlIdFormItemObjectMap[lFormItemObj.control_id_] = lFormItemObj;
                var lFormItemList = lResponseObj.mActionRefIdFormItemObjectMap[lFormItemObj.action_ref_id_];
                if(!lFormItemList)
                {
                    lFormItemList = [];
                    lResponseObj.mActionRefIdFormItemObjectMap[lFormItemObj.action_ref_id_] = lFormItemList;
                }

                var lContains = false;
                for(var lFiInd_0=0; lFiInd_0<lFormItemList.length; lFiInd_0++)
                {
                    if (lFormItemList[lFiInd_0].id_ === lFormItemObj.id_)
                    {
                        lContains = true;
                        break;
                    }
                }

                if(!lContains)
                {
                    lResponseObj.mActionRefIdFormItemObjectMap[lFormItemObj.action_ref_id_].push(lFormItemObj);
                }

                if(lResponseObj.pFormItemProperties)
                {
                    for(var lFipInd=0; lFipInd<lResponseObj.pFormItemProperties.length; lFipInd++)
                    {
                        var lFipObj = lResponseObj.pFormItemProperties[lFipInd];
                        if(lFormItemObj.id_ === lFipObj.cs_form_item_ref_id_)
                        {
                            var lFipCol = lFormItemObj.cs_form_item_properties_;
                            if(!lFipCol)
                            {
                                lFipCol = [];
                                lFormItemObj.cs_form_item_properties_ = lFipCol;
                            }
                            
                            lFipCol.push(lFipObj);
                        }
                    }
                }

                if(lResponseObj.pFormItemComputation)
                {
                    for(var lFicInd=0; lFicInd<lResponseObj.pFormItemComputation.length; lFicInd++)
                    {
                        var lFicObj = lResponseObj.pFormItemComputation[lFicInd];
                        if(lFormItemObj.id_ === lFicObj.cs_form_item_ref_id_)
                        {
                            var lFicCol = lFormItemObj.cs_form_item_computation_;
                            if(!lFicCol)
                            {
                                lFicCol = [];
                                lFormItemObj.cs_form_item_computation_ = lFicCol;
                            }
                            
                            lFicCol.push(lFicObj);
                        }
                    }
                }

                if(lResponseObj.pFormValidation)
                {
                    for(var lFvInd_0=0; lFvInd_0<lResponseObj.pFormValidation.length; lFvInd_0++)
                    {
                        var lFvObj_0 = lResponseObj.pFormValidation[lFvInd_0];
                        if(lFormItemObj.id_ === lFvObj.cs_form_item_ref_id_)
                        {
                            var lFvCol_0 = lFormItemObj.cs_form_validation_;
                            if(!lFvCol_0)
                            {
                                lFvCol_0 = [];
                                lFormItemObj.cs_form_validation_ = lFvCol;
                            }
                            
                            lFvCol_0.push(lFvObj_0);
                        }
                    }
                }
            }

            lCriteria.call_back_function_(lResponseObj, lCriteria);
        }
    }
}

export default CSServiceUtil;
