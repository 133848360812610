import CSConfigUtil from "./CSConfigUtil"
import { fnGetCurrentDate, fnGetCurrentTime, fnGetCurrentTimestamp } from "./CSDateTimeUtil"

var CSConstantUtil = 
{
    mRupee          : '&#8377;',

    gYearList       : null,
    gMonthList      : ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'],
    gFYMonthList    : ['APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC','JAN','FEB','MAR'],
    gLongMonthList  : 
    [   
        'January',      'February', 'March',    'April',
        'May',          'June',     'July',     'August', 
        'September',    'October',  'November', 'December'
    ],

    gMonthMap       : 
    {
        'JAN': 'January', 	'FEB': 'February',  'MAR': 'March', 'APR': 'April',
        'MAY': 'May', 		'JUN': 'June',      'JUL': 'July',	'AUG': 'August',
        'SEP': 'September',	'OCT': 'October',	'NOV': 'November',	'DEC': 'December'
    },

    gWeekList       : ['SUN','MON','TUE','WED','THU','FRI','SAT'],
    gLongWeekList   : ['Sunday','Moday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
    gComponentMap   : 
    {
        'DETAILS_HEADER'  : 'CS_DETAILS_HEADING_SECTION_1', 
        'DETAILS_SECTION' : 'CS_DETAILS_FORM_SECTION_1',
        'DETAILS_FORM'    : 'CS_DETAILS_FORM_1' 
    },

    gValidatePasswordMsg : 'Entered Passwords did not match',
    gResetPasswordMsg : 'OTP verification failed'
}

function cfGetYearList()
{
    if(!CSConstantUtil.gYearList)
    {
        let lCurDate = new Date();
        let lCurYear  = lCurDate.getFullYear();

        CSConstantUtil.gYearList = [];
        for(var lYearIndex=0; lYearIndex<20; lYearIndex++)
        {
            CSConstantUtil.gYearList.push(lCurYear - 10 + lYearIndex);
        }
    }

    return CSConstantUtil.gYearList;
}

function cfGetConstantValue(lParamName)
{
    var lParamValue = null;
    switch(lParamName)
    {
        case '#APP_CODE#':
            lParamValue = CSConfigUtil.mAppCode;
            break;
		
        case '#EMPLOYEE_ID#':
            lParamValue = CSConfigUtil.mEmployeeId;
            break;

        case '#ADMISSION_NO#':
            lParamValue = CSConfigUtil.mAdmissionNo;
            break;

        case '#MANAGER_ID#':
            lParamValue = CSConfigUtil.mManagerId;
            break;

        case '#EMPLOYEE_NAME#':
            lParamValue = CSConfigUtil.mEmployeeName;
            break;

        case '#EMPLOYEE_ID_NAME#':
            lParamValue = CSConfigUtil.mEmployeeId + ' - ' + CSConfigUtil.mEmployeeName;
            break;
            
        case '#STUDENT_NAME#':
            lParamValue = CSConfigUtil.mStudentName;
            break;

        case '#MANAGER_NAME#':
            lParamValue = CSConfigUtil.mManagerName;
            break;

        case '#USER_ID#':
            lParamValue = CSConfigUtil.mUserId;
            break;

        case '#TODAY#':
            lParamValue =  fnGetCurrentDate();
            break;

        case '#TODAY_DISPLAY#':
            lParamValue =  fnGetCurrentDate('display');
            break;

        case '#TIME_NOW#':
            lParamValue = fnGetCurrentTime();
            break;

        case '#TIMESTAMP_NOW#':
            lParamValue = fnGetCurrentTimestamp();
            break;

        case '#YEAR#':
            lParamValue = (new Date()).getFullYear();
            break;

        case '#MONTH#':
            lParamValue = (new Date()).getMonth() + 1;
            break;

        case '#EMPLOYEE_STATE_NAME#':
            lParamValue = CSConfigUtil.mState;
            break;

        case '#OFFICE_CODE#':
            lParamValue = CSConfigUtil.mOfficeCode;
            break;

        case '#DEPT_CODE#':
            lParamValue = CSConfigUtil.mDeptCode;
            break;

        case '#OFFICE_NAME#':
            lParamValue = CSConfigUtil.mOfficeName;
            break;

        case '#DEPT_NAME#':
            lParamValue = CSConfigUtil.mDeptName;
            break;

        case '#AY_CODE#':
            lParamValue = CSConfigUtil.mAYCode;
            break;

        case '#ACTIVE_AY_CODE#':
            lParamValue = CSConfigUtil.mCurrentAYCode;
            break;

        case '#ACTIVE_AY_NAME#':
            lParamValue = CSConfigUtil.mCurrentAYName;
            break;

        case '#ACTIVE_AY_OBJ#':
            lParamValue = CSConfigUtil.mActiveAcademicYearObj;
            break;

        case '#ACTIVE_FY_CODE#':
            lParamValue = CSConfigUtil.mCurrentFYCode;
            break;

        case '#BOARD_CODE#':
            lParamValue = CSConfigUtil.mBoardCode;
            break;

        case '#BOARD_NAME#':
            lParamValue = CSConfigUtil.mBoardName;
            break;

        case '#BRANCH_CODE#':
            lParamValue = CSConfigUtil.mBranchCode;
            break;

        case '#BRANCH_TYPE#':
            lParamValue = CSConfigUtil.mBranchType;
            break;

        case '#CLASS_CODE#':
            lParamValue = CSConfigUtil.mClassCode;
            break;

        case '#SECTION_CODE#':
            lParamValue = CSConfigUtil.mSectionCode;
            break;

        case '#BRANCH_NAME#':
            lParamValue = CSConfigUtil.mBranchName;
            break;

        case '#PETTY_CASH_ACCOUNT#':
            lParamValue = CSConfigUtil.mPettycashAccount;
            break;

        case '#USER_TYPE#':
            lParamValue = CSConfigUtil.mUserType;
            break;

        default:
            lParamValue = lParamName;
            break;
   }

   return lParamValue;
}

export { CSConstantUtil, cfGetConstantValue, cfGetYearList };