import { Calendar } from 'primereact/calendar'
import React from 'react'

export default function CSDatePicker(lProps) {
    return (
        <div >
            <label htmlFor={lProps.pLabelId} className=" block font-semibold required-field mb-1">
                Date of Birth
            </label>
            <Calendar
                id={lProps.pLabelId}
                value={lProps.pValue}
                onChange={(e) => lProps.pOnchange(lProps.pLabelId, e.value)} 
                required={lProps.pRequired}
                dateFormat='dd/mm/yy'
                showIcon />
        </div>
    )
}
