import React, { useEffect, useState } from 'react'

export default function CSInputText(props) {
    const [mStyle, setStyle] = useState({
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        backgroundColor: 'transparent',
        justifyContent: 'center',
        padding: '2px',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        margin: '0',
        overflow: 'visible',
        alignItems: 'unset',
        justifyContent: 'unset',
        //text properties//
        color: 'black',
        fontSize: '14px',
        textAlign: 'left',
        fontWeight: 'normal',
        lineHeight: 'normal',
        border: '.5px solid gray',
        borderRadius: '4px'
    }
    );
    const [mLabelStyle, setLabelStyle] = useState ('font-semibold')
    const [mChildren, setChildren] = useState(null);


    useEffect(() => {
        if (props) {
            /**
             * Initialize Component Styles
             */
            if(props.pRequired)
            {
                setLabelStyle('font-semibold required-field mb-2 ');
            }
            if (props.hasOwnProperty('width')) {
                setStyle((lStyle) => ({ ...lStyle, width: props.width }));
            }
            if (props.hasOwnProperty('color')) {
                setStyle((lStyle) => ({ ...lStyle, color: props.color }));
            }
            if (props.hasOwnProperty('fontSize')) {
                setStyle((lStyle) => ({ ...lStyle, fontSize: props.fontSize }));
            }
            if (props.hasOwnProperty('textAlign')) {
                setStyle((lStyle) => ({ ...lStyle, textAlign: props.textAlign }));
            }
            if (props.hasOwnProperty('fontWeight')) {
                setStyle((lStyle) => ({ ...lStyle, fontWeight: props.fontWeight }));
            }
            if (props.hasOwnProperty('lineHeight')) {
                setStyle((lStyle) => ({ ...lStyle, lineHeight: props.lineHeight }));
            }
            if (props.hasOwnProperty('height')) {
                setStyle((lStyle) => ({ ...lStyle, height: props.height }));
            }
            if (props.hasOwnProperty('gap')) {
                setStyle((lStyle) => ({ ...lStyle, gap: props.gap }));
            }
            if (props.hasOwnProperty('backgroundColor')) {
                setStyle((lStyle) => ({ ...lStyle, backgroundColor: props.backgroundColor }));
            }
            if (props.hasOwnProperty('justifyContent')) {
                setStyle((lStyle) => ({ ...lStyle, justifyContent: props.justifyContent }));
            }
            if (props.hasOwnProperty('padding')) {
                setStyle((lStyle) => ({ ...lStyle, padding: props.padding }));
            }
            if (props.hasOwnProperty('top')) {
                setStyle((lStyle) => ({ ...lStyle, top: props.top }));
            }
            if (props.hasOwnProperty('bottom')) {
                setStyle((lStyle) => ({ ...lStyle, bottom: props.bottom }));
            }
            if (props.hasOwnProperty('left')) {
                setStyle((lStyle) => ({ ...lStyle, left: props.left }));
            }
            if (props.hasOwnProperty('right')) {
                setStyle((lStyle) => ({ ...lStyle, right: props.right }));
            }
            if (props.hasOwnProperty('margin')) {
                setStyle((lStyle) => ({ ...lStyle, margin: props.margin }));
            }
            if (props.hasOwnProperty('overflow')) {
                setStyle((lStyle) => ({ ...lStyle, overflow: props.overflow }));
            }
            if (props.hasOwnProperty('alignItems')) {
                setStyle((lStyle) => ({ ...lStyle, alignItems: props.alignItems }));
            }
            if (props.hasOwnProperty('justifyContent')) {
                setStyle((lStyle) => ({ ...lStyle, justifyContent: props.justifyContent }));
            }
            /**
            * Add Children
            */
            if (props.hasOwnProperty('children')) {
                setChildren(props.children);
            }
        }
    }, [props]);

    return (
        <div className='p-2' >
            <label className={mLabelStyle} htmlFor={props.pLabelId} >
                {props.pLabel}
            </label>
            <input
                id={props.pLabelId}
                style={mStyle}
                value={props.mvalue}
                onChange={(e)=>props.pOnChange(e.target.id, e.target.value)}
                type={props.ltype}
                required={props.pRequired}
                >
                {mChildren}
            </input>
        </div>
    )
}
