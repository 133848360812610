var CSConfigUtil = 
{
    gAppId_Settings         : 'SETTINGS',
    gAppId_Calendar         : 'CONFIGURATION_CALENDAR',
    gAppId_Notifications    : 'NOTIFICATION_SUMMARY',
    gPageSize               : 25,

//    mAppCode              : 'SCMIPL',
//    mAppCode              : 'FIVE9',
//    mAppCode              : 'FIVE9_ED_TEST',
//    mAppCode              : 'CSPL',
//    mAppCode              : '99V_STG',
//    mAppCode              : '99V',
//    mAppCode              : 'EZ_TMP',
//    mAppCode              : 'EZ_USE_STG',
//    mAppCode              : 'EZ_USE',
//    mAppCode              : 'FR_USE',
//    mAppCode              : 'SVU',
//    mAppCode              : 'SVCO',
   mAppCode              : 'SBVDS',
    // mAppCode              : 'VENOBLE',
//    mAppCode              : 'CROSOL',
//    mAppCode              : 'FIVE9_DISPATCH_DEV',
//    mAppCode              : 'DESIGNER_STUDIO',
//    mAppCode              : 'CS_MKS',
//    mAppCode              : 'NCC_CMNP',
//    mAppCode              : 'CS_HRM',
//    mAppCode              : 'CS_SVDK',
//    mAppCode              : 'SZ_INAN_GJC_PROD',

    mUserId                 : '',
    mSessionId              : '',
    mDomainId               : 'CS_DAO',
    mUserType               : 'EMPLOYEE',

//    mProductCode            : 'CS-ECM',
//    mProductCode            : '99 Ventures',
    mProductCode            : 'EZ_USE',
//    mProductCaption         : 'Enterprise Capability Management',
    mProductCaption         : '',
//    mProductTitle           : 'Enterprise Capability Management',
    mProductTitle           : 'EZ Use',
    mLoginFormTitle         : 'Chinmmai Systemss',
    // mLoginFormLogo          : require('../assets/images/cs_logo_latest.png'),
    // mProductLogo            : require('../assets/images/cs_logo_latest.png'),
    // mCompanyLogo            : require('../assets/images/cs_logo_latest.png'),

    mStudentName            : null,
    mAdmissionNo            : null,
    mClassCode              : null,
    mSectionCode            : null,
    mBranchCode             : null,
    mBoardCode              : null,
    mAYCode                 : null,

    mReceiptHeading         : {},
    mReceiptSubHeading1     : {},
    mReceiptSubHeading2     : {},
    mReceiptHeadingList     : null,
    mReceiptSubHeading1List : null,
    mReceiptSubHeading2List : null,
    mReportHeading          : {},
    mReportSubHeading1      : {},
    mReportSubHeading2      : {},
    mReportHeadingList      : null,
    mReportSubHeading1List  : null,
    mReportSubHeading2List  : null,
    mDataFolder             : null,
    mDataFolderMap          : {},

    /**
     * Employee Details
     */
    mEmployee               : null,
    mEmployeeId             : null,
    mEmployeeName           : null,
    mManagerId              : null,
    mManagerName            : null,		
    mOfficeCode             : null,
    mOfficeName             : null,
    mBranchObj              : null,
    mBranchName             : null,
    mBranchType             : null,
    mBranchTypeCode         : null,
    mBranchEmail            : null,
    mDeptCode               : null,
    mDeptName               : null,
    mCountry                : 'India',
    mState                  : 'Telangana',
    mCity                   : 'Hyderabad',
    mCompanyCode            : null,
    mActiveAcademicYearObj  : null,
    mCurrentAYCode          : null,
    mCurrentAYName          : null,
    mCurrentFYCode          : null,
    mPettycashAccount       : null,
    mDateFormat             : 'DD-MM-YYYY',
    mDateSaveFormat         : 'YYYY-MM-DD',
    mLoginType              : '1'
}

export default CSConfigUtil;