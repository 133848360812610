import Axios from "axios"
import CSServiceUtil from "./CSServiceUtil"
import CSConfigUtil from "./CSConfigUtil"

class CSRequestUtil {
    static mAppCode = 'SBVDS';
    static mProductCode = 'SBVDS APP';
    static mProductCaption = 'SBVDS Institutions';
    static mProductTitle = 'SBVDS Institutions';
    static mLoginFormTitle = 'Chinmmai Systemss';
    static mLoginFormLogo = require('../assets/images/logo.png');
    static mProductLogo = require('../assets/images/logo.png');
    static mCompanyLogo = require('../assets/images/logo.png');

    static mUserId = 'cs_admin';
    static mSessionId = '1';
    static mDomainId = 'CS_DAO';
    static mUserType = 'GUEST';

    static mAuthenticateUrl = 'https://www.sbvds.org/control/react_authenticate.php';
    static mServiceUrl = 'https://www.sbvds.org/control/react_process_request.php';
    static mPropertiesUrl = 'https://www.sbvds.org/control/process_properties.php';
    static mFileUploadUrl = 'https://www.sbvds.org/control/fileupload.php';

    mRequestType = '';
    mCriteria = {};
    mRequestKeys = null;
    mData = {};
    mServiceCode = '';
    mRequestId = '';
    mDataType = '';

    constructor(request_type = '', service_code = '', data_type = '', request_id = '') {
        this.mServiceCode = service_code;
        this.mDataType = data_type;
        this.mRequestType = request_type;
        this.mRequestId = request_id;
    }

    cfSetAppCode(appCode) {
        this.mAppCode = appCode;
    }

    cfGetAppCode() {
        return this.mAppCode;
    }

    cfSetUserId(user_id) {
        this.mUserId = user_id;
    }

    cfGetUserId() {
        return this.mUserId;
    }

    cfSetSessionId(session_id) {
        this.mSessionId = session_id;
    }

    cfGetSessionId() {
        return this.mSessionId;
    }

    cfGetLoginObj() {
        var lgn = {};
        lgn.user_id = CSRequestUtil.mUserId;
        lgn.session_id = CSRequestUtil.mSessionId;

        return lgn;
    }

    cfSetRequestCriteria(crit_obj) {
        if (crit_obj) {
            var lCriteriaNames = Object.keys(crit_obj);
            var lCriteriaValues = Object.values(crit_obj);
            for (var lInd_0 = 0; lInd_0 < lCriteriaNames.length; lInd_0++) {
                if (lCriteriaValues[lInd_0]) {
                    this.mCriteria[lCriteriaNames[lInd_0]] = lCriteriaValues[lInd_0];
                }
            }
        }
    }

    cfSetRequestKeys(lReqKeys) {
        this.mRequestKeys = lReqKeys;
    }

    cfSetRequestData(data_obj) {
        this.mData = data_obj;
    }

    cfAuthenticate(user_id, passwd, login_attempt, call_back_fn) {
        var request = {};
        request.Authentication = { user_id: user_id, password: passwd, login_attempt: login_attempt };
        request.criteria = { user_id: user_id, app_code_: CSRequestUtil.mAppCode };
        request.login = { user_id: user_id, password: passwd, login_attempt: login_attempt };
        request.app_id = CSRequestUtil.mAppCode;
        if (this.mRequestKeys != null) {
            request.request_keys = this.mRequestKeys;
        }

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mAuthenticateUrl,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                data: JSON.stringify(request)
            }
        ).then(result => {
            if (result.hasOwnProperty('data')) {
                if (result.data.hasOwnProperty('status')) {
                    var lStatus = null;
                    if (result.data.status instanceof Array) {
                        lStatus = result.data.status[0];
                    }
                    else {
                        lStatus = result.data.status;
                    }

                    var lStatusObj = {};

                    if (lStatus.code instanceof Array) {
                        lStatusObj.code = lStatus.code[0];
                    }
                    else {
                        lStatusObj.code = lStatus.code;
                    }

                    if (lStatus.message instanceof Array) {
                        lStatusObj.message = lStatus.message[0];
                    }
                    else {
                        lStatusObj.message = lStatus.message;
                    }

                    var lData = this.cfGetDataObj(result.data);
                    call_back_fn(lStatusObj, lData);
                }
            }
        }
        ).catch((error) => {
            console.log(error);
        }
        );
    }

    cfProcessRequest(call_back_fn) {
        if (this.mRequestType === 'GET') {
            this.cfProcessGetRequest(call_back_fn);
        }
        else if (this.mRequestType === 'SAVE') {
            this.cfProcessSaveRequest(call_back_fn);
        }
        else if (this.mRequestType === 'DELETE') {
            this.cfProcessSaveRequest(call_back_fn);
        }
        else if (this.mRequestType === 'GENERATE') {
            this.cfGenerateProgressCard(call_back_fn);
        }
        else if (this.mRequestType === 'OTP') {
            this.cfSendOtp(call_back_fn);
        }
    }

    cfProcessSaveRequest(call_back_fn) {
        let data_obj =
        {
            login: this.cfGetLoginObj(),
            app_id: (this.mAppCode) ? this.mAppCode : CSConfigUtil.mAppCode,
            domain_id_: (this.mServiceObj) ? this.mServiceObj.domain_id_ : CSConfigUtil.mDomainId,
            data_type_: this.mDataType,
            service_type_: this.mRequestType,
            criteria: this.mCriteria,
            data: this.mData
        };

        if (this.mRequestId) {
            data_obj.service_id_ = this.mRequestId;
            data_obj.request_id_ = this.mRequestId;
        }
        else {
            data_obj.service_id_ = this.mServiceCode;
        }

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mServiceUrl,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                data: JSON.stringify(data_obj)
            }
        ).then(lSaveResponse => {
            if (lSaveResponse.hasOwnProperty('data')) {
                if (typeof (call_back_fn) === 'function') {
                    if (lSaveResponse.data.hasOwnProperty('status') && lSaveResponse.data.status.hasOwnProperty('code')) {
                        call_back_fn(lSaveResponse.data.status, lSaveResponse.data, this.mData);
                    }
                    else if (lSaveResponse.data.hasOwnProperty('result') && lSaveResponse.data.result.hasOwnProperty('code')) {
                        call_back_fn(lSaveResponse.data.result, lSaveResponse.data, this.mData);
                    }
                    else {
                        if (lSaveResponse.data instanceof Object) {
                            if (!lSaveResponse.data.hasOwnProperty('code')) {
                                lSaveResponse.data.code = -1;
                            }
                        }
                        else {
                            var lCode = lSaveResponse.data;
                            lSaveResponse.data = {};
                            lSaveResponse.data.code = lCode;
                        }

                        call_back_fn(lSaveResponse.data);
                    }

                    if (this.mServiceCode) {
                        var lServiceUtil = CSServiceUtil.cfGetInstance();
                        var lServiceObject = lServiceUtil.cfGetServiceObject(this.mServiceCode);
                        if (lServiceObject && lServiceObject.hasOwnProperty('cs_service_properties_')) {
                            var lServiceProperties = lServiceObject.cs_service_properties_;
                            for (var lInd = 0; lInd < lServiceProperties.length; lInd++) {
                                var lspObj = lServiceProperties[lInd];
                                if ((lspObj.property_ === 'data_provider_') && (lspObj.type_ === 'refresh') && lspObj.value_) {
                                    lServiceUtil.cfGetDataFromDataProvider(lspObj.value_, null, true);
                                }
                            }
                        }
                    }
                }
                else {
                    console.log(lSaveResponse);
                }
            }
        }
        ).catch((error) => {
            console.log(error);
        }
        );
    }

    cfProcessGetRequest(call_back_fn, lDefaultLogin) {
        var lLoginObj = lDefaultLogin;
        if (!lLoginObj) {
            lLoginObj = this.cfGetLoginObj();
        }

        let data_obj =
        {
            login: lLoginObj,
            app_id: (this.mAppCode) ? this.mAppCode : CSConfigUtil.mAppCode,
            domain_id_: (this.mServiceObj) ? this.mServiceObj.domain_id_ : CSConfigUtil.mDomainId,
            data_type_: this.mDataType,
            service_type_: this.mRequestType,
            criteria: this.mCriteria
        };

        if (this.mRequestId) {
            data_obj.service_id_ = this.mRequestId;
            data_obj.request_id_ = this.mRequestId;
        }
        else {
            data_obj.service_id_ = this.mServiceCode;
        }

        if (this.mRequestKeys) {
            data_obj.request_keys = this.mRequestKeys;
        }

        if (!data_obj.criteria) {
            data_obj.criteria = {};
        }

        data_obj.criteria.logged_in_user_id_ = CSConfigUtil.mUserId;

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mServiceUrl,
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data_obj)
            }
        ).then(result => {
            if (result.hasOwnProperty('data')) {
                if (result.data) {
                    var lData = this.cfGetDataObj(result.data);
                    call_back_fn(lData, this.mCriteria);
                }
            }
        }
        ).catch((error) => {
            console.log(error);
            call_back_fn();
        }
        );
    }

    cfExportCsvFile(call_back_fn) {
        let data_obj =
        {
            login: this.cfGetLoginObj(),
            app_id: (this.mAppCode) ? this.mAppCode : CSConfigUtil.mAppCode,
            domain_id_: (this.mServiceObj) ? this.mServiceObj.domain_id_ : CSConfigUtil.mDomainId,
            data_type_: this.mDataType,
            service_type_: this.mRequestType,
            criteria: this.mCriteria,
            data: this.mData
        };

        if (this.mRequestId) {
            data_obj.service_id_ = this.mRequestId;
            data_obj.request_id_ = this.mRequestId;
        }
        else {
            data_obj.service_id_ = this.mServiceCode;
        }

        if (!data_obj.criteria) {
            data_obj.criteria = {};
        }

        data_obj.criteria.logged_in_user_id_ = CSConfigUtil.mUserId;

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mExportCsvUrl,
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data_obj)
            }
        ).then(result => {
            if (result.hasOwnProperty('data')) {
                if (result.data) {
                    var lData = this.cfGetDataObj(result.data);
                    call_back_fn(lData, this.mCriteria);
                }
            }
        }
        ).catch((error) => {
            console.log(error);
            call_back_fn();
        }
        );
    }

    cfDownload(call_back_fn) {
        let data_obj =
        {
            login: this.cfGetLoginObj(),
            app_id: (this.mAppCode) ? this.mAppCode : CSConfigUtil.mAppCode,
            domain_id_: (this.mServiceObj) ? this.mServiceObj.domain_id_ : CSConfigUtil.mDomainId,
            data_type_: this.mDataType,
            service_type_: this.mRequestType,
            criteria: this.mCriteria,
            data: this.mData
        };

        if (this.mRequestId) {
            data_obj.service_id_ = this.mRequestId;
            data_obj.request_id_ = this.mRequestId;
        }
        else {
            data_obj.service_id_ = this.mServiceCode;
        }

        if (!data_obj.criteria) {
            data_obj.criteria = {};
        }

        data_obj.criteria.logged_in_user_id_ = CSConfigUtil.mUserId;

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mDownloadUrl,
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data_obj)
            }
        ).then(result => {
            if (result.hasOwnProperty('data')) {
                if (result.data) {
                    var lData = this.cfGetDataObj(result.data);
                    call_back_fn(lData, this.mCriteria);
                }
            }
        }
        ).catch((error) => {
            console.log(error);
            call_back_fn();
        }
        );
    }

    cfSendOtp(lOtpVal, lContactList, call_back_fn) {
        let data_obj =
        {
            otp_val_: lOtpVal,
            contact_list_: lContactList
        };

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mOtpUrl,
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },

                data: JSON.stringify(data_obj)
            }
        ).then(result => {
            console.log(result);
            call_back_fn(result);
        }
        );
    }

    cfSmsNotification(lPaymentval, lStudentName, lBranchName, lContactList, call_back_fn) {
        let data_obj =
        {
            payment_val_: lPaymentval,
            student_name_: lStudentName,
            branch_name_: lBranchName,
            contact_list_: lContactList
        };

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mFeePaySMSUrl,
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },

                data: JSON.stringify(data_obj)
            }
        ).then(result => {
            console.log(result);
            call_back_fn(result);
        }
        );
    }

    cfNotification(lType, lCategory, lContactList, lServiceCode, lCriteria, lParams, call_back_fn) {
        let lServiceObject = null;
        if (lServiceCode) {
            lServiceObject = (CSServiceUtil.cfGetInstance()).cfGetServiceObject(lServiceCode);
        }

        let data_obj =
        {
            type_: lType,
            category_: lCategory,
            contact_list_: lContactList,
            contact_service_code_: lServiceCode,
            contact_service_object_: lServiceObject,
            criteria_: lCriteria,
            params_: lParams,
            login_: this.cfGetLoginObj()
        };

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mNotificationUrl,
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },

                data: JSON.stringify(data_obj)
            }
        ).then(result => {
            console.log(result);
            call_back_fn(result);
        }
        );
    }

    cfGenerateProgressCard(call_back_fn) {
        let data_obj =
        {
            login: this.cfGetLoginObj(),
            app_id: (this.mAppCode) ? this.mAppCode : CSConfigUtil.mAppCode,
            domain_id_: (this.mServiceObj) ? this.mServiceObj.domain_id_ : CSConfigUtil.mDomainId,
            data_type_: this.mDataType,
            service_type_: this.mRequestType,
            criteria: this.mCriteria,
            data: this.mData
        };

        if (this.mRequestId) {
            data_obj.service_id_ = this.mRequestId;
            data_obj.request_id_ = this.mRequestId;
        }
        else {
            data_obj.service_id_ = this.mServiceCode;
        }

        if (!data_obj.criteria) {
            data_obj.criteria = {};
        }

        data_obj.criteria.logged_in_user_id_ = CSConfigUtil.mUserId;

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mGenerateProgressCardUrl,
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },

                data: JSON.stringify(data_obj)
            }
        ).then(result => {
            if (result.hasOwnProperty('data')) {
                if (result.data) {
                    var lData = this.cfGetDataObj(result.data);
                    if (lData.hasOwnProperty('status')) {
                        var lStatus = (lData.status instanceof Array) ? lData.status[0] : lData.status;
                        call_back_fn(lStatus, this.mCriteria);
                    }
                    else {
                        call_back_fn(lData, this.mCriteria);
                    }
                }
            }
        }
        ).catch((error) => {
            console.log(error);
            call_back_fn();
        }
        );
    }

    cfGetPropertiesRequest(call_back_fn) {
        let data_obj =
        {
            app_id: (this.mAppCode) ? this.mAppCode : CSConfigUtil.mAppCode
        };

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mPropertiesUrl,
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data_obj)
            }
        ).then(result => {
            var lProperties = result.data;
            if (lProperties.hasOwnProperty("product_name")) {
                CSConfigUtil.mProductCode = lProperties.product_name;
            }

            if (lProperties.hasOwnProperty("product_title")) {
                CSConfigUtil.mProductTitle = lProperties.product_title;
            }

            if (lProperties.hasOwnProperty("product_caption")) {
                CSConfigUtil.mProductCaption = lProperties.product_caption;
            }

            if (lProperties.hasOwnProperty("login_form_title")) {
                CSConfigUtil.mLoginFormTitle = lProperties.login_form_title;
            }

            if (lProperties.hasOwnProperty("login_form_logo")) {
                CSConfigUtil.mLoginFormLogo = lProperties.login_form_logo;
            }

            if (lProperties.hasOwnProperty("company_logo")) {
                CSConfigUtil.mCompanyLogo = lProperties.company_logo;
            }

            if (lProperties.hasOwnProperty("company_name")) {
                CSConfigUtil.mCompanyName = lProperties.company_name;
            }

            if (lProperties.hasOwnProperty("product_logo")) {
                CSConfigUtil.mProductLogo = lProperties.product_logo;
            }

            if (lProperties.hasOwnProperty("login_type")) {
                CSConfigUtil.mLoginType = lProperties.login_type;
            }

            call_back_fn();
        });
    }

    cfUploadFile(lFiles, lFolderKey, call_back_fn) {
        if (lFiles) {
            for (let lfInd = 0; lfInd < lFiles.length; lfInd++) {
                let lDataFolder = "/var/www/sbvds/data/others";
                let lFile = lFiles[0];
                const lFormData = new FormData();
                lFormData.append("userfile", lFile, lFile.lastModified + lFile.name);
                lFormData.set('dataFolder', lDataFolder);

                Axios.post(CSRequestUtil.mFileUploadUrl, lFormData).then((lResponse) => {
                        call_back_fn(lResponse.data);
                    }
                )
                .catch((lErr) => {
                        console.log(lErr)
                        call_back_fn(-1);
                    }
                );
            }
        }
    }

    cfGetDataObj(lData, lVariable) {
        var lRetVal = {}
        if (!lVariable) {
            lVariable = 'collection_';
        }

        var lDataObj = [];
        if (lData) {
            var lKeys = Object.keys(lData);
            if (lKeys && (lKeys.length > 0) && (lKeys.length < 1000)) {
                var lValues = Object.values(lData);
                for (var lInd = 0; lInd < lKeys.length; lInd++) {
                    var lKey = lKeys[lInd];
                    var lDataVal = lValues[lInd];
                    if (lDataVal && (lDataVal instanceof Array)) {
                        for (var lValInd = 0; lValInd < lDataVal.length; lValInd++) {
                            var lValObj = lDataObj[lValInd];
                            if (!lValObj) {
                                lValObj = {}
                                lDataObj.push(lValObj);
                            }

                            lValObj[lKey] = lDataVal[lValInd];
                        }
                    }
                    else {
                        var lChildData = this.cfGetDataObj(lDataVal);
                        if (lChildData && lChildData.hasOwnProperty('collection_')) {
                            lRetVal[lKey] = lChildData.collection_;
                        }
                    }
                }
            }
        }

        lRetVal[lVariable] = lDataObj;

        return lRetVal;
    }
}

export default CSRequestUtil;