import React, { useContext, useEffect, useState } from 'react'
import { Carousel } from 'primereact/carousel';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';


import Slider from './Slider';
import S_Carousel from './S_Carousel';
import S_ImageSlider from './S_ImageSlider';
import { DataContext } from '../context/DataContext';
import { PhotoService } from '../services/PhotoService';
import { NavLink } from 'react-router-dom';
import CS_Alumni from './CS_Alumni';

export default function CS_HomePage() {
   const [images, setImages] = useState(null);
   const ProductData = useContext(DataContext);
   const { ProductName, Logo,  HeaderTitle, SubTitle1, SubTitle2, Image, BannerImage, Home, Menu1, Menu2, Menu3, Menu4, Menu5, Menu6, Menu7, Menu8, Menu9, Color } = ProductData
 
   useEffect(() => {
      PhotoService.getPhotos().then((data) => setImages(data.slice(0, 3)));
   }, []);
   const responsiveOptions = [
      {
         breakpoint: '1199px',
         numVisible: 1,
         numScroll: 1
      },
      {
         breakpoint: '991px',
         numVisible: 2,
         numScroll: 1
      },
      {
         breakpoint: '767px',
         numVisible: 1,
         numScroll: 1
      }
   ];
   const itemTemplate = (item) => {
      return <img src={item.image} alt={item.description} style={{ width: '100%', display: 'block' }} />;
   };

   return (

      <div>
         <div className="lg:block">
            <Carousel value={images} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel w-full border-bottom-3 border-primary-light mb-8" circular
               autoplayInterval={8000} itemTemplate={itemTemplate} showNavigators={false} showIndicators={true} />
         </div>

         <div className="surface-section px-4 py-4 md:px-6 lg:px-8">
            <div className="grid -mr-4 -ml-4 flex-column xl:flex-row">
               <div className="col-12 xl:col-6 p-4">
                  <div className="bg-yellow-50 border-round flex flex-column justify-content-center h-full p-6">
                     <p className="text-yellow-800 text-lg line-height-3 text-justify">Sri Brahmottama Vidyadana Sabha School (SBVD Sabha School) was established in 1911 by the visionary Sri Nukala Venkata Subbaiah garu, with the generous support of many kind donors. Situated in Pullampet Mandal, Annamayya district, Andhra Pradesh, our institution was founded on great aspirations and ideals, with a mission to provide quality education and nurture future leaders.</p>
                  </div>
               </div>
               <div className="col-12 xl:col-6 p-4"><img src="web/photos/image_ (1).png" alt="product" className="w-full" /></div>
               <div className="col-12 xl:col-6 p-4"><img src="web/photos/image_ (17).png" alt="product" className="w-full" /></div>
               <div className="col-12 xl:col-6 p-4">
                  <div className="bg-cyan-50 border-round flex flex-column justify-content-center h-full p-6">
                     <p className="text-cyan-800 text-lg line-height-3 text-justify">Originally starting as a high school, SBVD Sabha School has grown significantly over the years, now offering junior and degree college programs. Our alumni include a distinguished array of professionals such as teachers, doctors, engineers, and high-level administrators who are dedicated to serving our nation. Additionally, many of our graduates have settled abroad, pioneering in their respective fields and contributing to global progress.</p>
                  </div>
               </div>
               <div className="col-12 xl:col-6 p-4">
                  <div className="bg-yellow-50 border-round flex flex-column justify-content-center h-full p-6">
                     <p className="text-yellow-800 text-lg line-height-3 text-justify">At SBVD Sabha School, we believe in imparting not just academic excellence but also strong values and morals. Our commitment to holistic education ensures that our students grow into well-rounded individuals with a sense of responsibility, ethics, and a deep sense of pride in their heritage.</p>
                  </div>
               </div>
               <div className="col-12 xl:col-6 p-4">
                  <img src="web/photos/image_ (8).png" alt="product" className="w-full" />
               </div>
               <div className="col-12 xl:col-6 p-4">
                  <img src="web/photos/image_ (23).png" alt="product" className="w-full" />
               </div>
               <div className="col-12 xl:col-6 p-4">
                  <div className="bg-cyan-50 border-round flex flex-column justify-content-center h-full p-6">
                     <p className="text-cyan-800 text-lg line-height-3">SBVD Sabha School is more than just an educational institution; it is a divine abode of knowledge where students feel a strong sense of belonging. We take immense pride in our legacy and continue to strive towards shaping the future by nurturing young minds with the perfect blend of tradition and modernity.</p>
                  </div>
               </div>
            </div>
         </div>
         {/* <div className="surface-section px-4 py-4 md:px-6 lg:px-8"> */}
         {/* <div className="grid">
               <div className="col-12 lg:col-4 pr-0 lg:pr-4">
                  <div className="text-900 text-5xl font-bold mb-3">Meet our team</div>
                  <p className="text-700 text-lg line-height-3">Enim diam vulputate ut pharetra sit amet aliquam id. Consequat nisl vel pretium lectus quam id leo in. Nibh sit amet commodo nulla facilisi nullam vehicula. Nunc eget lorem dolor sed viverra ipsum nunc aliquet.</p>
               </div>
               <div className="col-12 lg:col-8">
                  <div className="grid">
                     <div className="col-12 lg:col-6 p-3">
                        <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" className="mb-2 w-2" />
                        <div className="font-medium text-xl mb-1 text-900">Name</div>
                        <span className="text-600 font-medium">Designer</span>
                        <p className="line-height-3 mt-3 mb-3">Vulputate ut pharetra sit amet. Netus et malesuada fames ac turpis egestas. Elit eget gravida cum sociis natoque penatibus. Urna id volutpat lacus laoreet non curabitur. In ante metus dictum at.</p>

                     </div>
                     <div className="col-12 lg:col-6 p-3">
                        <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" className="mb-2 w-2" />
                        <div className="font-medium text-xl mb-1 text-900">Name</div>
                        <span className="text-600 font-medium">Designer</span>
                        <p className="line-height-3 mt-3 mb-3">Risus feugiat in ante metus dictum. Et egestas quis ipsum suspendisse ultrices. Risus pretium quam vulputate dignissim suspendisse. Justo nec ultrices dui sapien.</p>

                     </div>
                     <div className="col-12 lg:col-6 p-3">
                        <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" className="mb-2 w-2" />
                        <div className="font-medium text-xl mb-1 text-900">Name</div>
                        <span className="text-600 font-medium">Designer</span>
                        <p className="line-height-3 mt-3 mb-3">Donec et odio pellentesque diam volutpat commodo sed egestas. Blandit massa enim nec dui nunc mattis enim ut tellus. Suspendisse faucibus interdum posuere lorem ipsum.</p>

                     </div>
                     <div className="col-12 lg:col-6 p-3">
                        <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" className="mb-2 w-2" />
                        <div className="font-medium text-xl mb-1 text-900">Name</div>
                        <span className="text-600 font-medium">Designer</span>
                        <p className="line-height-3 mt-3 mb-3">Pretium aenean pharetra magna ac placerat vestibulum. Est ullamcorper eget nulla facilisi etiam dignissim diam quis enim. Fringilla urna porttitor rhoncus dolor purus non enim..</p>

                     </div>
                  </div>
               </div>
            </div> */}
         {/* </div> */}
         <CS_Alumni/>
         <div className="border-top-1 border-300 pt-5 text-center">
            <img src={Logo} alt="Image" height="96" />
            <div className=" font-bold uppercase text-2xl flex justify-content-center flex-column align-items-center lg:flex-row " style={{ color: Color }}>
               {ProductName}
            </div>
            <ul className='flex justify-content-center gap-8 list-none'>
                        <li>
                            <NavLink to="/">{Home}</NavLink>
                        </li>
                        {/* <li>
                                <NavLink to="/about">{Menu1}</NavLink>
                            </li> */}
                        <li>
                            <NavLink to="/gallery">{Menu2}</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/blogs">{Menu3}</NavLink>
                        </li> */}
                        {/* <li>
                            <NavLink to="/admin">{Menu4}</NavLink>
                        </li>  */}
                        <li>
                            <NavLink to="/contact">{Menu5}</NavLink>
                        </li>
                        </ul>
            {/* <div className="text-center"><a className="mr-5 text-sm text-600 cursor-pointer">Address : Pullampet, Andhra Pradesh 516107</a><a className="text-sm text-600 cursor-pointer">Call:7989049707, 9398903833 </a></div> */}
         </div>
      </div>
   )
}
